import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import '../../assets/css/membresia.css';

import banner from '../../assets/images/segunda_reunion/MEMEBRESIA/membresia_ampip.png';
import banner_en from '../../assets/images/segunda_reunion/MEMEBRESIA/membership_eng.png';
import receso from '../../assets/images/segunda_reunion/AGENDA/receso_break_ampip_agenda.png';
import energia from '../../assets/images/segunda_reunion/MEMEBRESIA/iconos/energia_a.png';
import inmoviliario from '../../assets/images/segunda_reunion/MEMEBRESIA/iconos/inmobiliarios_a.png';
import telecomunicacion from '../../assets/images/segunda_reunion/MEMEBRESIA/iconos/telecomunicaicones_a.png';
import construccion from '../../assets/images/segunda_reunion/MEMEBRESIA/iconos/cosntruccion_a.png';

import desarrollo from '../../assets/images/segunda_reunion/MEMEBRESIA/iconos/desarrolllo ind_a.png';
import fibra from '../../assets/images/segunda_reunion/MEMEBRESIA/iconos/fibras_a.png';
import fondos from '../../assets/images/segunda_reunion/MEMEBRESIA/iconos/fondosdeinversion_a.png';
import gobiernos from '../../assets/images/segunda_reunion/MEMEBRESIA/iconos/gobiernosestatales_a.png';


const Membresia = (props) => {
    const [agenda, setRevista] = useState(false);
    const { idioma } = props;


    return (
        <Row className='back_membresia_home'>
            <Col span={20} offset={2}>
                <Row justify='center' align='middle' style={{ position: 'relative' }}>
                    <Col span={14} style={{ position: 'relative' }}>
                        <img src={idioma === 'es' ? banner : banner_en} style={{ width: '100%' }} />
                    </Col>
                    <Col span={24} offset={0} style={{ position: 'absolute', width: '100%' }}>
                        <p className="color_blanco textos_titulos_seccion" style={{ textAlign: 'center' }}>{idioma === 'es' ? 'membresía 2022 ' : 'Schedule'}</p>
                    </Col>
                </Row>
            </Col>

            <Col span={20} offset={2}>
                {/*<p className="color_blanco textos_titulos_secciones" style={{ textAlign: 'center' }}>{idioma === 'es' ? 'Socios 2022' : 'Partners 2022'}</p>*/}
                <br />
            </Col>
            <Col span={20} offset={2}>
                <Row justify='space-around' align='middle'>
                    <Col xs={20} md={5} className="back_gris_membresia margin_chico">
                        <div className='dvi'>
                            <Link to='/membresia/desarrolladores-industriales'>
                                <p className='btn_azul_membresia'>{idioma === 'es' ? 'Desarrolladores Industriales' : 'Industrial developers'}</p>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={20} md={5} className="back_gris_membresia margin_chico">
                        <div className='dvi'>
                            <Link to='/membresia/fibras'>
                                <p className='btn_azul_membresia'>FIBRA’s</p>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={20} md={5} className="back_gris_membresia margin_chico">
                        <div className='dvi'>
                            <Link to='/membresia/fondos-de-inversion'>
                                <p className='btn_azul_membresia'>{idioma === 'es' ? <>Fondos de Inversión</> : 'Investment funds'}</p>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={20} md={5} className="back_gris_membresia margin_chico">
                        <div className='dvi'>
                            <Link to='/membresia/gobiernos-estatales'>
                                <p className='btn_azul_membresia'>{idioma === 'es' ? <>Gobiernos Estatales</> : 'State goverments'}</p>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col span={20} offset={2}>
                <br />
                <hr style={{ backgroundColor: '#ffffff', border: 'none', height: '0.5px' }} />
            </Col>
            <Col span={20} offset={2}>
                <p className="color_blanco textos_titulos_secciones_2" style={{ textAlign: 'center' }}>{idioma === 'es' ? 'Afiliados sectoriales' : 'Sector affiliates'}</p>
                <br />
            </Col>
            <Col span={20} offset={2}>
                <Row justify='space-around'>
                    <Col xs={20} md={5} className="back_azum_membresia margin_chico">
                        <div className='dvi'>
                            <Link to='/membresia/energia'>
                                <p className='btn_azul_membresia' >{idioma === 'es' ? 'Energía' : 'Energy'}</p>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={20} md={5} className="back_azum_membresia margin_chico">
                        <div className='dvi'>
                            <Link to='/membresia/inmobiliario'>
                                <p className='btn_azul_membresia' >{idioma === 'es' ? 'Inmobiliario' : 'Real state'}</p>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={20} md={5} className="back_azum_membresia margin_chico">
                        <div className='dvi'>
                            <Link to='/membresia/construccion-y-seguridad'>
                                <p className='btn_azul_membresia'>{idioma === 'es' ? 'Construcción y Seguridad' : 'Construction and Security'}</p>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={20} md={5} className="back_azum_membresia margin_chico">
                        <div className='dvi'>
                            <Link to='/membresia/telecomunicaciones-y-transporte'>
                                <p className='btn_azul_membresia'>{idioma === 'es' ? 'Telecomunicaciones y Transporte' : 'Telecommunications and Transportation'}</p>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={0} md={24}><br /></Col>
                    <Col xs={20} md={5} className="back_azum_membresia margin_chico">
                        <div className='dvi'>
                            <Link to='/membresia/financiero'>
                                <p className='btn_azul_membresia'>Financiero</p>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Col>

        </Row>
    )
}
export default Membresia;