import React, { useState } from "react";
import { Row, Col, Modal } from "antd";
import "../../assets/css/ponentes.css";
import { Link } from "react-router-dom";

import sergio from "../../assets/images/segunda_reunion/CONFERENCISTAS/sergioarguelles_ampip.jpg";
import peter from "../../assets/images/segunda_reunion/CONFERENCISTAS/Peter_Linneman_ampip_.jpg";
import matt from "../../assets/images/segunda_reunion/CONFERENCISTAS/Matt_Brady_ampip.jpg";
import hamid from "../../assets/images/segunda_reunion/CONFERENCISTAS/Hamid_Moghada_ampip.jpg";
import banner from "../../assets/images/tercera_reunion/CONFERENCISTAS/conferencistas_txt.png";

import sergio_con from "../../assets/images/segunda_reunion/CONFERENCISTAS/Semblanza_Sergio_Argaelles_espanol.jpg";
import peter_con from "../../assets/images/segunda_reunion/CONFERENCISTAS/Semblanza_Peter_Linneman_Ingles.pdf";
import matt_con from "../../assets/images/segunda_reunion/CONFERENCISTAS/Semblanza_Matt_Brady_ingles.pdf";
import hamid_con from "../../assets/images/segunda_reunion/CONFERENCISTAS/Semblanza_Hamid_Moghadam_espanol.pdf";
import { CloseOutlined } from "@ant-design/icons";

import samuel from "../../assets/images/tercera_reunion/CONFERENCISTAS/samuelgarcia.png";
import yuri from "../../assets/images/tercera_reunion/CONFERENCISTAS/Yuri_de_los_Santos.png";
import tom from "../../assets/images/tercera_reunion/CONFERENCISTAS/Pearson_Tom.png";
import jesus from "../../assets/images/tercera_reunion/CONFERENCISTAS/Jesus-Reyes-Heroles.png";
import javier from "../../assets/images/tercera_reunion/CONFERENCISTAS/FJSolares.png";
import francisco_gonzalez from "../../assets/images/tercera_reunion/CONFERENCISTAS/Francisco N_González Díaz.png";
import carlos from "../../assets/images/tercera_reunion/CONFERENCISTAS/Carlos Salazar.png";
import maximo from "../../assets/images/tercera_reunion/CONFERENCISTAS/Maximo Vedoya.png";
import marco from "../../assets/images/tercera_reunion/CONFERENCISTAS/Marco Cosio.png";
import luis from "../../assets/images/tercera_reunion/CONFERENCISTAS/luisgarcia_postigo.png";
import jose from "../../assets/images/tercera_reunion/CONFERENCISTAS/Jose Ma Garza.png";
import ildefonso from "../../assets/images/tercera_reunion/CONFERENCISTAS/Idelfonso-Guajardo.png";
import humberto from "../../assets/images/tercera_reunion/CONFERENCISTAS/Humberto Fernández Montes.png";
import david from "../../assets/images/tercera_reunion/CONFERENCISTAS/David Gutierrez.png";
import sergio_img from "../../assets/images/tercera_reunion/CONFERENCISTAS/sergio_arguelles.png";
import david_gu from "../../assets/images/tercera_reunion/CONFERENCISTAS/David Gutiérrez Muguerza.png";

const expositor = [
  //{nombre:'', img: avatar,img_hover:avatar_hover, hash:'/conferencistas#' },
  {
    nombre: "Samuel A. García Sepúlveda",
    empresa: "",
    cargo: "Gobernador del Estado de Nuevo León",
    img: samuel,
    url: sergio_con,
    semblanza: [''],
  },
  {
    nombre: "Sergio Argüelles González",
    empresa: "",
    cargo: "Presidente AMPIP",
    img: sergio_img,
    url: sergio_con,
    semblanza: [''],
  },
  {
    nombre: "Yuri De Los Santos Llamas",
    empresa: "CEMEX México",
    cargo: "Vicepresidente Constructores de CEMEX México",
    img: yuri,
    url: sergio_con,
    semblanza: [
      <>
        Yuri De Los Santos Llamas, Vicepresidente Constructores de CEMEX México,
        es Ingeniero Civil por el ITESM y obtuvo la maestría en Administración
        en la misma institución.
        <br />
        Inicio su carrera en CEMEX en 1995, formando parte del programa
        Profesionistas en Desarrollo. Desde entonces ha colaborado en diversas
        geografías, áreas operativas y comerciales de CEMEX.
        <br />
        Fue responsable de la operación de concreto en diferentes ciudades y
        regiones en México entre el periodo 1997 y 2014. Su carrera lo ha
        llevado a desempeñarse en otros países donde CEMEX tiene operación. En
        2014 fue asignado como responsable de la operación de CEMEX Nicaragua,
        posteriormente en 2019 fue nombrado responsable de las operaciones de
        Cemex en el Clúster Centroamérica Norte hasta 2022. Durante su estancia
        en Centroamérica fue miembro de las junta directivas de las cámaras de
        construcción por varios años en Guatemala y Nicaragua, miembro de la
        junta directiva de las cámaras de comercio de México – Centroamérica y
        miembro y presidente de la junta directiva de los Institutos del Cemento
        y Concreto en Centroamérica.
        <br />
        A su regreso a México en el mes de junio del 2022 se incorpora como
        responsable de la Vicepresidencia del Segmento Constructores en CEMEX
        México en donde lidera un equipo cuyo objetivo es la atención a los
        constructores, ofreciendo soluciones y servicios integrales y
        sustentables para contribuir al desarrollo de proyectos de
        Infraestructura, vivienda, comercio e industria en el país.
        <br />
        Así mismo, está al frente del Centro de Innovación y Desarrollo que
        tiene como objetivo el desarrollo tecnológico del concreto y otros
        materiales para la construcción en México y Latinoamérica.
      </>,
    ],
  },
  {
    nombre: "Thomas O. Pearson",
    empresa: "",
    cargo: "Executive VicePresident SIOR, MBA",
    img: tom,
    url: sergio_con,
    semblanza: [
      <>
        Tom is an executive vice president and active member of Colliers’
        Logistics and Transportation Solutions Group and a member of the Supply
        Chain Real Estate Advisors Team with Chris Teesdale, SIOR and Brad Balke
        in the Dallas-Fort Worth office of Colliers International. His 40-year
        industrial real estate career spans every facet of industrial real
        estate.
        <br />
        Tom played a key role in handling the national relocation of the
        distribution center for a Fortune 100 company in addition to selling and
        leasing significant properties in multiple markets for some of the
        largest corporations in the United States.
      </>,
    ],
  },
  {
    nombre: "JESÚS REYES HEROLES",
    empresa: "",
    cargo: "Presidente de Structura",
    img: jesus,
    url: sergio_con,
    semblanza: [
      <>
        Economista del Instituto Tecnológico Autónomo de México (1976), y con
        estudios de derecho en la UNAM. Doctor en Economía por el Instituto
        Tecnológico de Massachusetts (MIT) en 1980.
        <br />
        Actualmente es Presidente de StructurA, organización que agrupa a GEA,
        ProA, MBD y EnergeA. Ha participado en diversos consejos consultivos,
        como el de Energy Intelligence Group (EI), Deutsche Bank América Latina.
        Morgan Stanley Energy Partners, Mitsui de México, y el Centro Mario
        Molina para Estudios Estratégicos sobre Energía y Medio Ambiente.
        Actualmente es miembro del Consejo de Administración del banco Santander
        México.
        <br />
        Durante su carrera profesional ha combinado la práctica privada con el
        servicio público. Su último puesto fue el de Director General de
        Petróleos Mexicanos, que desempeñó de diciembre de 2006 a septiembre de
        2009. Previo a ese cargo, de 2001 a noviembre de 2006, Jesús Reyes
        Heroles desarrolló actividades privadas como Presidente de StructurA.
        Durante esos seis años fue miembro de diversos consejos de
        administración, entre los que destacan el de Wal-Mart México y Banamex
        Citigroup.
        <br />
        De octubre de 1997 a noviembre de 2000, Reyes Heroles fue Embajador de
        México en los Estados Unidos de América. Antes fue Secretario de
        Energía, de 1995 a 1997, en el gabinete del Presidente Ernesto Zedillo.
        En ese cargo se desempeñó como Presidente de los consejos de
        administración de Pemex y CFE, entre otras organizaciones. Previamente
        fue Director General de Banobras, el banco de desarrollo para
        infraestructura.
        <br />
        Reyes Heroles también ha combinado actividades académicas durante su
        carrera. Ha sido profesor en la licenciatura y la maestría de economía
        del ITAM. En 1976 recibió el primer lugar del Premio Nacional de
        Economía Banamex, en el nivel de investigación. Las actividades
        filantrópicas han sido un interés continuo de Jesús Reyes Heroles, quien
        actualmente es Presidente del Patronato de la Fundación Gonzalo Río
        Arronte y ex Presidente del Consejo Consultivo del Agua.
        <br />
        Publica quincenalmente en el periódico "El Universal".
      </>,
    ],
  },
  {
    nombre: "FRANCISCO JAVIER SOLARES ALEMÁN",
    empresa: "",
    cargo:
      "Presidente Nacional de la Cámara Mexicana de la Industria de la Construcción (CMIC)",
    img: javier,
    url: sergio_con,
    semblanza: [
      <>
        FRANCISCO JAVIER SOLARES ALEMÁN <br />
        Nacido en la Ciudad de México <br />
        <br />
        FORMACIÓN ACADÉMICA:
        <br />
        Ingeniero Civil por la Facultad de Ingeniería de la Universidad Nacional
        Autónoma de México. (1970-1974) <br />
        <br />
        EXPERIENCIA PROFESIONAL:
        <br />
        Inició su trayectoria profesional como calculista en 1973 y
        posteriormente como analista de costos y jefe de departamento técnico.
        <br />
        En 1977 funda su primera empresa constructora, Penta Grupo Constructor,
        S.A. de C.V. y actualmente Preside los Consejos de Administración de
        Construcción de Infraestructura, S.A. de C.V., Técnica XXI, S.A. de
        C.V., Promotora Inmobiliaria Sol, S.A. de C.V., GM1250, S.A. de C.V.,
        empresas especializadas en construcción y desarrollo de proyectos
        inmobiliarios. Más de 45 años dedicados a la construcción, participando
        en el desarrollo de una gran cantidad de obras civiles, principalmente
        de edificación. <br />
        <br />
        ACTIVIDAD ACADÉMICA:
        <br />
        Distinguido por la Universidad Nacional Autónoma de México en el año de
        1998 con la Medalla al Mérito Universitario.
        <br />
        Profesor desde 1973 de la Facultad de Ingeniería de la UNAM.
        Actualmente, profesor de asignatura a nivel posgrado. Profesor de cursos
        de educación continua impartidos en varias universidades de México y el
        extranjero.
        <br />
        Coautor y autor de varios libros para la impartición de clases.
        <br />
        <br />
        OTRAS ACTIVIDADES GREMIALES:
        <br />
        Socio Vitalicio de la Sociedad de Exalumnos de la Facultad de
        Ingeniería. <br />
        Socio Vitalicio del Colegio de Ingenieros Civiles de México, Habiendo
        sido integrante de su Consejo Directivo. <br />
        <br />
        PARTICIPACIÓN EN LA CÁMARA MEXICANA DE LA INDUSTRIA DE LA CONSTRUCCIÓN.{" "}
        <br />
        Actualmente, Presidente Nacional desde marzo de 2021 habiendo sido
        anteriormente Secretario, Tesorero, varias veces Vicepresidente
        Nacional, Presidente de la Delegación Ciudad de México, desempeñando
        también, varios puestos de representación desde 1985.
      </>,
    ],
  },
  {
    nombre: "David Gutiérrez Muguerza",
    empresa: "",
    cargo: "Director General DeAcero / Presidente de CANACERO",
    img: david_gu,
    url: sergio_con,
    semblanza: [''],
  },
  {
    nombre: "Francisco N. González Díaz",
    empresa: "Industria Nacional de Autopartes, A.C.",
    cargo: "Presidente Ejecutivo de la Industria Nacional de Autopartes (INA)",
    img: francisco_gonzalez,
    url: sergio_con,
    semblanza: [
      <>
        Francisco N. González Díaz es Presidente Ejecutivo de la Industria
        Nacional de Autopartes, A.C. desde enero de 2022, donde representa los
        intereses de más de 900 empresas en el país.
        <br />
        Cuenta con una trayectoria profesional de más de 20 años enfocada a
        promover a México y sus industrias. Fue Director General de Bancomext y
        de ProMéxico. Fue Embajador de México ante la República Federal de
        Alemania. Recibió la condecoración en dos ocasiones de la Cruz de
        Comendador de la Orden del Mérito de la República Federal de Alemania.
        En 2014, recibió el Reconocimiento al Mérito Profesional del ITAM por su
        trayectoria. Asimismo, le fue otorgada la Encomienda de Número de la
        Orden de Isabel la Católica de España y la Condecoración de la Orden de
        Dannebrog en grado de Comendador de Primera Clase de Dinamarca. En la
        iniciativa privada participa activamente como empresario de los sectores
        de salud, comercio exterior, turismo, así como de banca y finanzas. Es
        egresado de la licenciatura en Administración de Empresas por el ITAM y
        de la maestría en Administración por el Tec de Monterrey.
      </>,
    ],
  },
  {
    nombre: "Carlos Salazar Lomelín",
    empresa: "",
    cargo: "Empresario",
    img: carlos,
    url: sergio_con,
    semblanza: [
      <>
        Carlos Salazar fue electo Presidente del Consejo Coordinador Empresarial
        el 18 de enero de 2019. Tomó protesta de su cargo el 27 de febrero del
        2019 para el período 2019- 2020., fue reelegido para el período del
        2021-2022 y en marzo del 2022 concluyó su gestión.
        <br />
        Tiene una experiencia profesional de más de 45 años en el sector
        privado, en la academia y en organizaciones sociales.
        <br />
        Destaca su trayectoria, de 1973 a 2019, en FEMSA, empresa multinacional
        líder, con sede en Monterrey y que participa en las industrias de
        bebidas, como accionista mayoritario de Coca-Cola FEMSA y segundo
        accionista de Heineken; y en comercio al detalle, a través de FEMSA
        Comercio, operadora de las tiendas OXXO.
        <br />
        Carlos Salazar ocupó diversos cargos directivos en las filiales de
        FEMSA, destacando las posiciones de Director General de la Cervecería
        Cuauhtémoc-Moctezuma y Director General de Coca-Cola FEMSA, compañía en
        la que, bajo su liderazgo, el valor de mercado creció a una tasa anual
        compuesta de más de 21% y se expandió más de once veces.
        <br />
        Fue también Director General de FEMSA de enero de 2014 a diciembre de
        2017, desde donde impulsó la incursión y el desarrollo de las divisiones
        de comercio, salud y combustibles. Durante su gestión, la compañía
        creció hasta tener alrededor de 300 mil colaboradores en 11 países.
        <br />
        A partir de enero de 2018 ha emprendido diversos proyectos
        empresariales, relacionados con la fabricación y venta de galletas y
        cereales, además de negocios inmobiliarios. Ha participado activamente
        en distintas organizaciones sociales y empresariales. Fue Presidente de
        la Comisión Siglo XXI en la ciudad de Monterrey y Promotor del Centro de
        Exposiciones y Convenciones de Monterrey (CINTERMEX). En 2014 fue
        designado Presidente Ejecutivo del Consejo Nuevo León.
        <br />
        Ha recibido diferentes reconocimientos en estos campos, entre ellos los
        de Ciudadano Distinguido de Nuevo León; el de Ejecutivo Distinguido
        Nacional otorgado por la Asociación de Ventas y Mercadotecnia, el Premio
        Muguerza-Garret por el Desarrollo de la Comunidad. En Enero de 2016
        recibió del Club Rotario de Nuevo León el reconocimiento por su
        participación social y de la CANACO de Monterrey por su labor
        empresarial. En abril del 2018 fue nombrado presidente del Comité de
        Propuesta Económica de COPARMEX. En 2020 recibió la Presea Othón Páez
        Garza Sada como Ciudadano de Legado Ejemplar. Y en febrero de 2021 fue
        nombrado Líder Global Inlfuyente por la AACSB International (Association
        to Advance Collegiate Schools of Business).
        <br />
        Ha sido profesor de economía durante más de cuarenta años en el
        Instituto Tecnológico y de Estudios Superiores de Monterrey, es el
        actual Presidente del Consejo de las Escuelas de Negocios del Tec y
        miembro del Consejo Académico de la misma institución. Es egresado de la
        carrera de Economía y cuenta con estudios de postgrado en Administración
        de Empresas del Tecnológico de Monterrey. Realizó estudios de postgrado
        en Desarrollo Económico en Italia y un programa de Dirección
        Administrativa en el Instituto Para la Alta Dirección de Empresas
        (IPADE) en México, además de otros estudios en diferentes países.
      </>,
    ],
  },
  {
    nombre: "Maximo Vedoya",
    empresa: "",
    cargo: "CEO Ternium",
    img: maximo,
    url: sergio_con,
    semblanza: [''],
  },
  {
    nombre: "Marco Cosío Gaggero",
    empresa: "SIEMENS",
    cargo:
      "Vicepresidente Smart Infraestructure Siemens, México, Centroamérica y El Caribe",
    img: marco,
    url: sergio_con,
    semblanza: [''],
  },
  {
    nombre: "Luis García Postigo",
    empresa: "",
    cargo: "",
    img: luis,
    url: sergio_con,
    semblanza: [''],
  },
  {
    nombre: "José María Garza Treviño",
    empresa: "",
    cargo: "Grupo GP",
    img: jose,
    url: sergio_con,
    semblanza: [''],
  },
  {
    nombre: "Ildefonso Guajardo Villarreal",
    empresa: "",
    cargo: "Diputado Federal por Nuevo León y Diputado Local por Monterrey",
    img: ildefonso,
    url: sergio_con,
    semblanza: [''],
  },
  {
    nombre: "Humberto Fernández Montes",
    empresa: "",
    cargo: "Jefe del Departamento de Desarrollo de Piezas, KIA México",
    img: humberto,
    url: sergio_con,
    semblanza: [''],
  },
];
const Ponentes = (props) => {
  const [hover, setHover] = useState(false);
  const [selec_person, setSelectPerson] = useState(0);
  const { idioma } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  let ver_ponentes = expositor.map((seccion, index) => {
    return (
      <Col xs={20} md={10} lg={5} >
        <div className="contenedor_imagen_con">
          <img src={seccion.img} className="imagen_con" alt="" />
          <div className="overlay_con">
            <div className="text_con">
              <p className="nombre_con">{seccion.nombre}</p>
              
              {/*<p className="empresa_con">{seccion.empresa}</p><p className="cargo_con">{seccion.cargo}</p>*/}
              <br />
              <p style={{display:'none'}}>
                {seccion.semblanza != '' ?
                <span
                onClick={() => {
                  showModal();
                  setSelectPerson(index);
                }}
                className="btn_desc_con"
              >
                SEMBLANZA
              </span>
              :
              <></>}
              </p>
            </div>
          </div>
        </div>
        <br />
      </Col>
    );
  });
  let ver_texto_modal = expositor
    .filter((item, index) => {
      if (selec_person === index) {
        return true;
      } else {
        return false;
      }
    })
    .map((seccion, index) => {
      return (
        <Col xs={24}>
          <p className="nombre_modal">{seccion.nombre}</p>
          <p className="empresa_modal">{seccion.empresa}</p>
          <p className="cargo_modal">{seccion.cargo}</p>
          <br />
          <p className="semblanza_modal">{seccion.semblanza}</p>
          <br />
        </Col>
      );
    });

  return (
    <>
      <Row justify="center" align="middle" className="contenedor_ponentes">
        <Col span={24} offset={0}>
          <Row justify="center" align="middle" style={{ position: "relative" }}>
            <Col span={24} style={{ position: "relative" }}>
              <img src={banner} style={{ width: "100%" }} />
            </Col>
            <Col
              span={24}
              offset={0}
              style={{ position: "absolute", width: "100%" }}
            >
              <p
                className="color_azul textos_titulos_secciones"
                style={{ textAlign: "center" }}
              >
                {idioma === "es" ? "Conferencistas" : "Conferencias"}
              </p>
            </Col>
          </Row>
        </Col>
        <Col span={20}>
          <Row justify="space-around">{ver_ponentes}</Row>
        </Col>
      </Row>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        title={false}
        closeIcon={<CloseOutlined style={{ color: "#ffffff" }} />}
      >
        {ver_texto_modal}
      </Modal>
    </>
  );
};
export default Ponentes;
