import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import '../../assets/css/membresia.css';
import { FastBackwardOutlined, LeftOutlined } from '@ant-design/icons';

import img_artha from '../../assets/images/tercera_reunion/MEMBRESIA/FRONTIER.jpg';
import img_odonnell from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/odonnell.png';
import img_fortem from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/fondos/fortem.png';
import img_hines from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/fondos/hines.png';
import img_pgim from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/fondos/pgim.png';
import img_walton from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/fondos/walton.png';

const logo = [
    { nombre: '', img:img_artha, link: '', url: '' },
    { nombre: '', img:img_fortem, link: '', url: '' },
    { nombre: '', img:img_hines, link: '', url: '' },
    { nombre: '', img:img_odonnell, link: '', url: '' },
    { nombre: '', img:img_pgim, link: '', url: '' },
    { nombre: '', img:img_walton, link: '', url: '' },
];


const Fondos = (props) => {
    const ver_logos = logo.map(item => {
        return (<>
            <Col xs={10} lg={7} className="contenedor_targeta_membresia">
                <Row justify='center'>
                    <Col span={16}>
                        <img alt={item.nombre} src={item.img} style={{ width: '100%' }} />
                    </Col>
                    <Col span={0}>
                        <p className='nombre_membresia'>{item.nombre}</p>
                        <a href={item.url} className="link_membresia" target='_blank'>{item.link}</a>
                    </Col>
                </Row>
            </Col>
        </>)
    }
    );
    const { idioma } = props;

    return (
        <Row>
            <Col xs={0} md={24}>
                <br /><br /><br /><br /><br /><br />
            </Col>
            <Col xs={24} md={0}>
                <br /><br /><br />
                <br />
            </Col>
            <Col span={24}>
                <Row align='middle'>
                    <Col xs={{span:4, offset:1}} lg={{span:4, offset:2}}>
                        <Link className='regresar_membresia' to='/#membresia'><LeftOutlined />Regresar</Link>
                    </Col>
                    <Col xs={{span:16,offset:0}} lg={{span:12,offset:0}}>
                        <p className='titulo_tipo_membresia'>SOCIOS</p>
                    </Col>
                </Row>
            </Col>
            <Col span={24} offset={0} className='contenedor_membresias_interior'>
                <Row justify='center' align='middle'>
                    <Col xs={20} md={8}>
                        <p className='titulo_membresia'>Fondos de Inversión</p>
                        <br />
                    </Col>
                    <Col span={20}>
                        <Row justify="space-around">
                            {ver_logos}
                        </Row>
                    </Col>
                    <Col xs={20} lg={0}>
                        <br /><br /><br /><br /><br /><br />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
export default Fondos;