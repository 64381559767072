import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import '../../assets/css/membresia.css';
import Slider from "react-slick";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import img_advence from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/advence.png';
import img_aerotech from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/aerotech.png';
import img_alveo from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/alveo.png';
import img_americaninsutries from '../../assets/images/tercera_reunion/MEMBRESIA/logos_grupoanfitrion__americanindustries.jpg';
import img_amistad from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/amistad.png';
import img_atisa from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/atisa.png';
import img_avante from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/avante.png';
import img_ayusa from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/ayusa.png';
import img_bermudez from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/bermudez.png';
import img_bts from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/bts.png';
import img_cactus from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/cactus valley.png';
import img_calafia from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/calafia.png';
import img_cbp from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/cbp.png';
import img_ciesa from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/ciesa.png';
import img_construcciones from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/construcciones ms.png';
import img_cpa from '../../assets/images/tercera_reunion/MEMBRESIA/CPA.jpg';
import img_davisa from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/davisa.png';
import img_elflorido from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/elflorido.png';
import img_eqt_exeter from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/eqt_exeter.png';
import img_evalta from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/evalta.png';
import img_finsa from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/finsa.png';
import img_gpdesarrollos from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/gpdesarrollos.png';
import img_iamsa from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/iamsa.png';
import img_intermex from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/intermex.png';
import img_interpuertomty from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/interpuertomty.png';
import img_kampus from '../../assets/images/tercera_reunion/MEMBRESIA/KAMPUS.jpg';
import img_lintel from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/lintel.png';
import img_logistik from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/logistik.png';
import img_marabis from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/marabis.png';
import img_maran from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/maran.png';
import img_meor from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/meor.png';
import img_millennium from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/millennium.png';
import img_mztaerospace from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/mztaerospace.png';
import img_nelson from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/nelson.png';
import img_nicoya from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/nicoya.png';
import img_odonnell from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/odonnell.png';
import img_oradel from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/oradel.png';
import img_parqueindutrialPYME from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/parqueindutrialPYME.png';
import img_parqueopcion from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/parqueopcion.png';
import img_piasa from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/piasa.png';
import img_pimsa from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/pimsa.png';
import img_prologis from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/prologis.png';
import img_prosperity from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/prosperity.png';
//import img_puertadelnorte from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/puertadelnorte.png';
import img_rocadesarrollos from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/rocadesarrollos.png';
import img_san_julian from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/san_julian.png';
import img_skyplus from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/skyplus.png';
import img_stiva from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/stiva.png';
import img_tetakawi from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/tetakawi.png';
import img_vesta from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/vesta.png';
import img_vynmsa from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/vynmsa.png';
import img_worldport from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/worldport.png';
import img_wtcsanluispotosi from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/wtcsanluispotosi.png';
import img_yucataninsdutrialparks from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/yucataninsdutrialparks.png';
import img_american_industries_san_jorge from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/desarrolladores/american_industries_san_jorge.png';
import img_terraregia from '../../assets/images/tercera_reunion/MEMBRESIA/logos_grupoanfitrion__terra regia.jpg';

const logo = [
    { nombre: '', img: img_advence, link: '', url: '' },
    { nombre: '', img: img_aerotech, link: '', url: '' },
    //{ nombre: '', img: img_alveo, link: '', url: '' },
    { nombre: '', img: img_americaninsutries, link: '', url: '' },
    { nombre: '', img: img_amistad, link: '', url: '' },
    { nombre: '', img: img_atisa, link: '', url: '' },
    { nombre: '', img: img_avante, link: '', url: '' },
    { nombre: '', img: img_ayusa, link: '', url: '' },
    { nombre: '', img: img_bermudez, link: '', url: '' },
    { nombre: '', img: img_bts, link: '', url: '' },
    { nombre: '', img: img_cactus, link: '', url: '' },
    { nombre: '', img: img_calafia, link: '', url: '' },
    { nombre: '', img: img_cbp, link: '', url: '' },
    { nombre: '', img: img_ciesa, link: '', url: '' },
    { nombre: '', img: img_construcciones, link: '', url: '' },
    { nombre: '', img: img_cpa, link: '', url: '' },
    { nombre: '', img: img_davisa, link: '', url: '' },
    { nombre: '', img: img_elflorido, link: '', url: '' },
    { nombre: '', img: img_eqt_exeter, link: '', url: '' },
    { nombre: '', img: img_evalta, link: '', url: '' },
    { nombre: '', img: img_finsa, link: '', url: '' },
    { nombre: '', img: img_gpdesarrollos, link: '', url: '' },
    { nombre: '', img: img_iamsa, link: '', url: '' },
    { nombre: '', img: img_intermex, link: '', url: '' },
    { nombre: '', img: img_interpuertomty, link: '', url: '' },
    { nombre: '', img: img_lintel, link: '', url: '' },
    { nombre: '', img: img_marabis, link: '', url: '' },
    { nombre: '', img: img_logistik, link: '', url: '' },
    { nombre: '', img: img_kampus, link: '', url: '' },
    { nombre: '', img: img_maran, link: '', url: '' },
    { nombre: '', img: img_meor, link: '', url: '' },
    { nombre: '', img: img_millennium, link: '', url: '' },
    { nombre: '', img: img_mztaerospace, link: '', url: '' },
    { nombre: '', img: img_nelson, link: '', url: '' },
    { nombre: '', img: img_nicoya, link: '', url: '' },
    //{ nombre: '', img: img_odonnell, link: '', url: '' },
    { nombre: '', img: img_oradel, link: '', url: '' },
    { nombre: '', img: img_parqueindutrialPYME, link: '', url: '' },
    { nombre: '', img: img_parqueopcion, link: '', url: '' },
    { nombre: '', img: img_piasa, link: '', url: '' },
    { nombre: '', img: img_pimsa, link: '', url: '' },
    { nombre: '', img: img_prologis, link: '', url: '' },
    { nombre: '', img: img_prosperity, link: '', url: '' },
    //{ nombre: '', img: img_puertadelnorte, link: '', url: '' },
    { nombre: '', img: img_rocadesarrollos, link: '', url: '' },
    { nombre: '', img: img_san_julian, link: '', url: '' },
    { nombre: '', img: img_skyplus, link: '', url: '' },
    { nombre: '', img: img_stiva, link: '', url: '' },
    { nombre: '', img: img_tetakawi, link: '', url: '' },
    { nombre: '', img: img_vesta, link: '', url: '' },
    { nombre: '', img: img_vynmsa, link: '', url: '' },
    { nombre: '', img: img_worldport, link: '', url: '' },
    { nombre: '', img: img_wtcsanluispotosi, link: '', url: '' },
    { nombre: '', img: img_yucataninsdutrialparks, link: '', url: '' },
    { nombre: '', img: img_american_industries_san_jorge, link: '', url: '' },
    { nombre: '', img: img_terraregia, link: '', url: '' },
];

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "grey", borderRadius:'50%' }}
        onClick={onClick}
      />
    );
  }

  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "grey", borderRadius:'50%'  }}
        onClick={onClick}
      />
    );
  }
const Desarrolladores = (props) => {
    const settings = {
        className: "center",
        centerMode: false,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 4,
        speed: 500,
        rows: 3,
        slidesPerRow: 1,
        arrows: true,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    centerPadding: "60px",
                    slidesToShow: 2,
                    rows: 3,
                    centerMode: false,
                }
            }
        ]
      };

    const ver_logos = logo.map(item => {
        return (<>
            <Col xs={23} lg={20} className="contenedor_targeta_membresia">
                <img alt={item.nombre} src={item.img} style={{ width: '100%' }} />
            </Col>
        </>)
    }
    );
    const { idioma } = props;

    return (
        <Row>
            <Col xs={0} md={24}>
                <br /><br /><br /><br /><br /><br />
            </Col>
            <Col xs={24} md={0}>
                <br /><br /><br />
                <br />
            </Col>
            <Col span={24}>
                <Row align='middle'>
                    <Col xs={{ span: 4, offset: 1 }} lg={{ span: 4, offset: 2 }}>
                        <Link className='regresar_membresia' to='/#membresia'><LeftOutlined />Regresar</Link>
                    </Col>
                    <Col xs={{ span: 16, offset: 0 }} lg={{ span: 12, offset: 0 }}>
                        <p className='titulo_tipo_membresia'>SOCIOS</p>
                    </Col>
                </Row>
            </Col>
            <Col span={24} offset={0} className='contenedor_membresias_interior'>
                <Row justify='center' align='middle'>
                    <Col xs={20} md={8}>
                        <p className='titulo_membresia'>Desarrolladores Industriales</p>
                        <br />
                    </Col>
                    <Col span={20}>
                        <Row justify="space-around">

                        </Row>
                        <Slider {...settings}>
                            {ver_logos}
                        </Slider>
                    </Col>
                    <Col xs={20} lg={20}>
                        <br /><br /><br /><br /><br /><br />
                        <br /><br /><br /><br /><br /><br />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
export default Desarrolladores;