import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Row, Col } from 'antd';
import '../../assets/css/footer.css';
import { Element } from 'react-scroll';

import rede_twitter from '../../assets/images/segunda_reunion/FOOTER/twitter_ampip.png';
import rede_ln from '../../assets/images/segunda_reunion/FOOTER/linkedin_ampip.png';
import logo from '../../assets/images/segunda_reunion/FOOTER/logoampip.png';
import hast_vision from '../../assets/images/segunda_reunion/FOOTER/vision2223_ampip.png';

const Footer = () => {

  return (
    <>
      <Row justify="center" align="middle" style={{backgroundColor:'#242752', padding:'1% 0%'}}>
        
        <Col xs={{span:0, offset:0}} md={{span:4,offset:0}} style={{ padding: '5px 10px', textAlign: 'right' }}>
          <a href="https://ampip.org.mx/es/" target="_blank" className="link_sitio monserrat">
            <img src={logo} style={{width:'150px'}} />
          </a>
        </Col>
        <Col xs={{span:7, offset:0}} md={{span:0,offset:0}} style={{ padding: '5px 10px', textAlign: 'right' }}>
          <a href="https://ampip.org.mx/es/" target="_blank" className="link_sitio monserrat">
            <img src={logo} style={{width:'100px'}} />
          </a>
        </Col>
        <Col xs={{span:0, offset:0}} md={{span:12,offset:0}} style={{ padding: '5px 10px', textAlign: 'center' }}>
          <a href="https://ampip.org.mx/es/" target="_blank" className="link_sitio monserrat">www.ampip.org.mx</a>
        </Col>
        <Col xs={{ offset: 1, span: 7 }} md={{ offset: 2, span: 1 }} style={{ padding: '5px 10px' }}>
          <a href="https://twitter.com/ampip" target="_blank"><img className="redes" src={rede_twitter} /></a>
        </Col>
        <Col xs={{ offset: 0, span: 4 }} md={{ offset: 0, span: 1 }} style={{ padding: '5px 10px' }}>
          <a href="https://www.linkedin.com/company/asociaci-n-mexicana-de-parques-industriales" target="_blank"><img className="redes" src={rede_ln} /></a>
        </Col>
      </Row>
    </>
  );
}

export default Footer;