import React from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import ICalendarLink from "react-icalendar-link";
import { Element } from "react-scroll";

import Agenda from "./Agenda";
import Ponentes from "./Ponentes";

import "../../assets/css/webinars.css";

import banner from "../../assets/images/tercera_reunion/HOME/banner_principal.jpg";
import img_bienvenida from "../../assets/images/tercera_reunion/ACERCA/fotos_ampip_acercade.png";

import btn_ayuda from "../../assets/images/tercera_reunion/btn_whastapp.png";
import btn_ayuda_en from "../../assets/images/tercera_reunion/btn_whastapp.png";

import logo_banner_ergistro from "../../assets/images/tercera_reunion/HOME/logo_3reunios_ampip.png";

import fecha from "../../assets/images/tercera_reunion/HOME/icono_calendario.png";
import lugar from "../../assets/images/tercera_reunion/HOME/icono_ubicacion.png";

import back_datos from "../../assets/images/tercera_reunion/back_estadisticas.jpg";

import "../../assets/css/conferencia.css";
import Contador from "../evento/Contador";
import Membresia from "../membresia/Membresia";
import Hospedaje from "../membresia/Hospedaje";

const Webinars = (props) => {
  const { idioma } = props;
  

  return (
    <>
      <Row>
        <Col span={24}>
          <Row style={{ position: "relative" }}>
            <Col span={24} style={{ position: "relative" }}>
              <img src={banner} style={{ width: "100%" }} />
            </Col>
            <Col
              span={24}
              offset={0}
              className="contenedor_banner_menu"
              style={{ position: "absolute", width: "100%" }}
            >
              <Row justify="center">
                <Col xs={0} md={14}>
                  <br />
                  <Contador
                    dateTarget="09/07/2022 10:00:00 AM"
                    idioma={idioma}
                  />
                  <br />
                </Col>
                <Col span={14}>
                  <img src={logo_banner_ergistro} style={{ width: "100%" }} />
                </Col>

                <Col xs={0} md={24}>
                  <br />
                  <br />
                </Col>
                <Col xs={12} md={8}>
                  <p className="text_banner">
                    <img src={fecha} className="img_banner_icono" /> 7 al 9 de
                    septiembre 2022
                  </p>
                </Col>
                <Col xs={12} md={8}>
                  <p className="text_banner">
                    <img src={lugar} className="img_banner_icono" /> Monterrey,
                    N.L.
                  </p>
                </Col>
                <Col xs={0} md={14}>
                  <br />
                  <br />
                </Col>
                <Col xs={24}>
                  <br />
                  <Link to="registro" className="btn_aqua">
                    {idioma === "es" ? "REGISTRO" : "REGISTER"}
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="contenedor_conferencias">
        <Element name="#acerca-de" />
        <Col span={24} offset={0} className>
          <Row align="middle" className="back_bienvenidos">
            <Col xs={{ span: 20, offset: 2 }}>
              <p
                className="color_blanco textos_titulos_secciones"
                style={{ textAlign: "left" }}
              >
                Acerca de
              </p>
              <br />
              <br />
            </Col>
            <Col xs={{ span: 20, offset: 2 }} md={{ span: 10, offset: 2 }}>
              <p className="textos_home">
                <strong className="color_aqua">
                  Manufactura Avanzada y Competitividad
                </strong>
                <br />
                <br />
                Las cadenas de suministro son una de las palancas más
                importantes para que las empresas generen un impacto positivo en
                el mundo, por ello son el sistema circulatorio de la economía
                global y brindan acceso a las cosas que necesitamos para
                respaldar el comercio y sustentar la vida.
                <br />
                <br />
                Las empresas han necesitado adaptarse a los cambios globales y
                avances que están transformando los sistemas de fabricación y
                las cadenas de valor, y por ende, los espacios inmobiliarios no
                son la excepción.
                <br />
                <br />
                No hay duda de los gobiernos deben repensar su agenda
                estratégica industrial para responder a las megatendencias
                actuales y a la inminente conexión de ecosistemas regionales de
                fabricación en todo el mundo que busca facilitar el intercambio
                de conocimientos entre regiones para fortalecer los sistemas de
                suministro y fomentar la resiliencia y la innovación.
                <br />
                <br />
                Debemos seguir{" "}
                <strong className="color_aqua">
                  preparándonos para el futuro, porque los desarrolladores
                  inmobiliarios y los actores relacionados con los parques
                  industriales, somos una ventana de competitividad, basada en
                  infraestructura de calidad,
                </strong>{" "}
                planificación ambientalmente sostenible y el uso enfocado de la
                innovación tecnológica.
                <br />
                <br />
                ¿Cómo construir un ecosistema inmobiliario que pueda responder y
                adaptarse a desafíos imprevistos?
              </p>
              <br />
            </Col>
            <Col xs={{ span: 0, offset: 0 }} md={{ span: 12, offset: 0 }}>
              <img src={img_bienvenida} className="img_100" />
              <br />
              <br />
              <br />
              <Link to="/registro" className="btn_aqua2">
                REGISTRO
              </Link>
              <br />
              <br />
            </Col>
            <Col xs={{ span: 22, offset: 2 }} md={{ span: 0, offset: 0 }}>
              <Link to="/registro" className="btn_aqua">
                REGISTRO
              </Link>
              <br />
              <br />
              <img src={img_bienvenida} className="img_100" />
            </Col>
          </Row>
        </Col>
        <Col id="agenda" span={24} className="back_agenda">
          <Element name="#agenda"></Element>
          <Agenda idioma={idioma} />
        </Col>
        <Col id="conferencistas" span={24}>
          <Element name="#conferencistas"></Element>
          <Ponentes idioma={idioma} />
        </Col>
        <Col id="membresia" span={24}>
          <Element name="#membresia"></Element>
          <Membresia idioma={idioma} />
        </Col>
        <Col id="hospedaje" span={24}>
          <Element name="#hospedaje"></Element>
          <Hospedaje idioma={idioma} />
        </Col>
        <Col span={24} className="back_degradado" style={{ padding: "2% 0% " }}>
          <Row justify="center">
            <Col xs={20} md={8}>
              <p
                style={{
                  color: "#ffffff",
                  fontSize: "14px",
                  textAlign: "left",
                }}
              >
                <strong>Central de Reservaciones</strong>
                <br />
                Tel. 81 8100 7080 | 81 8399 7000 <br />
                Email.{" "}
                <a
                  href="mailto:reservaciones@safihotel.com"
                  style={{ color: "#ffffff", textDecoretion: "none" }}
                >
                  reservaciones@safihotel.com
                </a>
              </p>
            </Col>
            <Col xs={20} md={6}>
              <p
                style={{
                  color: "#ffffff",
                  fontSize: "14px",
                  textAlign: "left",
                }}
              >
                <strong>Contacto</strong>
                <br />
                TANIA ORTIZ, CEL 8114765553 <br />
                Email.{" "}
                <a
                  href="mailto:rp6@safihotel.com"
                  style={{ color: "#ffffff", textDecoretion: "none" }}
                >
                  rp6@safihotel.com
                </a>
              </p>
            </Col>
            <Col xs={20} md={6}>
              <p
                style={{
                  color: "#ffffff",
                  fontSize: "14px",
                  textAlign: "left",
                }}
              >
                <strong>Horario</strong>
                <br />
                Lun-Vie 09:00 a 17:00 h<br /> Sábados 09:00 a 12:00 h
              </p>
            </Col>
          </Row>
        </Col>

        <Col span={0}>
          <Element name="#"></Element>
          <Ponentes />
        </Col>
        <Col span={24} className="back_morado paddin_contenedor">
          <Row align="middle">
            <Col xs={{ span: 12, offset: 2 }} md={{ span: 12, offset: 3 }}>
              <p className="dudas_btn">
                {idioma === "es"
                  ? "Tengo dudas sobre mi inscripción"
                  : "I have questions about my registration"}
              </p>
            </Col>
            <Col xs={{ span: 0, offset: 0 }} md={{ span: 6, offset: 0 }}>
              <a
                href={
                  idioma === "es"
                    ? "https://api.whatsapp.com/send?phone=525557400333&text=Tengo%20problemas%20con%20mi%20registro%20en%20AMPIP."
                    : "https://api.whatsapp.com/send?phone=525557400333&text=I%20have%20problems%20with%20my%20AMPIP%20registration."
                }
                target="_blank"
              >
                <img
                  src={idioma === "es" ? btn_ayuda : btn_ayuda_en}
                  style={{ width: "180px" }}
                />
              </a>
            </Col>
            <Col xs={{ span: 8, offset: 0 }} md={{ span: 0, offset: 0 }}>
              <a
                href={
                  idioma === "es"
                    ? "https://api.whatsapp.com/send?phone=525557400333&text=Tengo%20problemas%20con%20mi%20registro%20en%20AMPIP."
                    : "https://api.whatsapp.com/send?phone=525557400333&text=I%20have%20problems%20with%20my%20AMPIP%20registration."
                }
                target="_blank"
              >
                <img
                  src={idioma === "es" ? btn_ayuda : btn_ayuda_en}
                  style={{ width: "100px" }}
                />
              </a>
            </Col>
          </Row>
        </Col>
        <Col span={24} className="">
          <Row justify="center" align="middle" style={{ position: "relative" }}>
            <Col span={24} style={{ position: "relative" }}>
              <img src={back_datos} style={{ width: "100%" }} />
            </Col>
            <Col
              span={20}
              offset={0}
              style={{ position: "absolute", width: "100%" }}
            >
              <Row justify="space-around" align="middle">
                <Col xs={5} md={5}>
                  <p className="datos_texto">
                    + DE 3,700
                    <br />
                    <span className="datos_texto_chico">
                      EMPRESAS INSTALADAS
                    </span>
                  </p>
                </Col>
                <Col xs={5} md={5}>
                  <p className="datos_texto">
                    <span className="datos_texto_chico">PRESENCIA EN</span>
                    <br />
                    27 ESTADOS
                    <br />
                    <span className="datos_texto_chico">
                      DE LA REPÚBLICA MEXICANA
                    </span>
                  </p>
                </Col>
                <Col xs={5} md={5}>
                  <p className="datos_texto">
                    + DE 400
                    <br />
                    <span className="datos_texto_chico">
                      PARQUES INDUSTRIALES
                    </span>
                  </p>
                </Col>
                <Col xs={5} md={5}>
                  <p className="datos_texto">
                    2.9 MILLONES
                    <br />
                    <span className="datos_texto_chico">
                      DE EMPLEOS GENERADOS
                    </span>
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Webinars;
