import React, { useState } from "react";
import { Row, Col, Modal } from "antd";
import { Link } from "react-router-dom";
import "../../assets/css/agenda.css";

import banner from "../../assets/images/segunda_reunion/AGENDA/agenda_ampip.png";
import presidente from "../../assets/images/tercera_reunion/AGENDA/sergioarguelle_agenda.png";
import video from "../../assets/images/tercera_reunion/AGENDA/video_icono_ampip.png";
import generico from "../../assets/images/tercera_reunion/AGENDA/logoagenda_ampip_sitio_.png";
import receso from "../../assets/images/tercera_reunion/AGENDA/receso_break_ampip_agenda.png";

import Carlos_Salazar from "../../assets/images/tercera_reunion/AGENDA/Carlos_Salazar.png";
import FJSolares from "../../assets/images/tercera_reunion/AGENDA/FJSolares.png";
import Francisco_Gonzalez_Diaz from "../../assets/images/tercera_reunion/AGENDA/Francisco_Gonzalez_Diaz.png";
import Idelfonso_Guajardo from "../../assets/images/tercera_reunion/AGENDA/Idelfonso_Guajardo.png";
import Jesus from "../../assets/images/tercera_reunion/AGENDA/Jesus-Reyes-Heroles.png";
import Jose_Ma_Garza from "../../assets/images/tercera_reunion/AGENDA/Jose_Ma_Garza.png";
import Marco_Cosio from "../../assets/images/tercera_reunion/AGENDA/Marco_Cosio.png";
import Maximo_Vedoya from "../../assets/images/tercera_reunion/AGENDA/Maximo_Vedoya.png";
import Pearson_Tom from "../../assets/images/tercera_reunion/AGENDA/Pearson_Tom.png";
import Yuri_de_los_Santos from "../../assets/images/tercera_reunion/AGENDA/Yuri_de_los_Santos.png";
import luisgarcia_postigo from "../../assets/images/tercera_reunion/AGENDA/luisgarcia_postigo.png";
import ponentes_ampip from "../../assets/images/tercera_reunion/AGENDA/ponentes_ampip.png";
import samuelgarcia from "../../assets/images/tercera_reunion/AGENDA/samuelgarcia.png";
import sergio_arguelles_ from "../../assets/images/tercera_reunion/AGENDA/sergio_arguelles_.png";
import sergioarguelle_agenda from "../../assets/images/tercera_reunion/AGENDA/sergioarguelle_agenda.png";
import david_gutierrez_muguerza from "../../assets/images/tercera_reunion/AGENDA/David Gutiérrez Muguerza.png";
import humberto_fernandez_montes from "../../assets/images/tercera_reunion/AGENDA/Humberto Fernández Montes.png";

import pdf_ingles from "../../assets/images/segunda_reunion/AGENDA/AMPIP_Meeting_Agenda_31.jpg";
import { CloseOutlined } from "@ant-design/icons";


const Agenda = (props) => {
  const [dia, setDia] = useState(1);
  const [ver_order, setVerOrder] = useState(0);
  const { idioma } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const conferencia = [
    {
      dia: 1,
      hora: "16:00 - 18:30",
      actividad: [
        {
          personas: [
            {
              img: "",
              nombre: [<span>SESIONES SIMULTÁNEAS DE TRABAJO</span>],
              cargo: [<span></span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [
            <span className="">
              Cuarta Sesión Ordinaria de Consejo Directivo
            </span>,
          ],
          sub: [
            <span>
              <i>Safi Metropolitan, Salón Cristal</i>
              <br />
              <br />
            </span>,
          ],
          desc: [<></>],
        },
        {
          personas: [""],
          panelistas: [""],
          moderadores: [""],
          titulo: [<span className="">Sesiones ordinarias de Comités</span>],
          sub: [""],
          desc: [
            <>
              <span>
                Comité de Promoción 
                <i className="color_aqua"> Safi Valle, Salón Gran Picasso</i> <span
            onClick={() => {
              showModal();
              setVerOrder(1);
            }}
            className="color_gris_claro"
            style={{cursor:'pointer'}}
          >
            (Ver orden del día)
          </span>
              </span>
              <br />
              <span>Comité ASG </span>
              <i className="color_aqua">Safi Valle, Salón Miro</i>  <span
            onClick={() => {
              showModal();
              setVerOrder(2);
            }}
            className="color_gris_claro"
            style={{cursor:'pointer'}}
          >
            (Ver orden del día)
          </span>
              <br />
              <span>Comité de Infraestructura y Seguridad</span>
              <i className="color_aqua"> Safi Valle, Salón Veneto</i>  <span
            onClick={() => {
              showModal();
              setVerOrder(3);
            }}
            className="color_gris_claro"
            style={{cursor:'pointer'}}
          >
            (Ver orden del día)
          </span>
            </>,
          ],
        },
      ],
      back: "borde_agenda",
    },
  
    {
      dia: 1,
      hora: "19:00 – 23:00",
      actividad: [
        {
          personas: [
            {
              img: presidente,
              nombre: [<span>Sergio Argüelles González</span>],
              cargo: [<span>Presidente AMPIP</span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [
            <span className="">CENA DE BIENVENIDA</span>,
            <i className="sub_agenda color_aqua">
              {" "}
              Safi Metropolitan, Gran Salón
            </i>,
          ],
          sub: [],
          desc: [<></>],
        },
        {
          personas: [
            {
              img: luisgarcia_postigo,
              nombre: [<span>Luis García Postigo</span>],
              cargo: [""],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [<span className="">Conferencia Decidir es Renunciar</span>],
          sub: [],
          desc: [<></>],
        },
      ],
      back: "back_dispar",
    },
    {
      dia: 2,
      hora: "08:30 – 09:00",
      actividad: [
        {
          personas: [
            {
              img: presidente,
              nombre: [<span>Sergio Argüelles González</span>],
              cargo: [<span>Presidente AMPIP</span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [<span className="">BIENVENIDA</span>],
          sub: [<span></span>],
          desc: [<></>],
        },
        {
          personas: [
            {
              img: samuelgarcia,
              nombre: [<span>Samuel A. García Sepúlveda </span>],
              cargo: [<span>Gobernador del Estado de Nuevo León</span>],
            },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [
            <span className="">INAUGURACIÓN</span>,
          ],
          sub: [<span></span>],
          desc: [<></>],
        },
      ],
      back: "borde_agenda",
    },
    {
      dia: 2,
      hora: "09:00 – 09:15",
      actividad: [
        {
          personas: [
            { img: "", nombre: [<span></span>], cargo: [<span></span>] },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [<span className="">Ajuste de tiempo</span>],
          sub: [<span></span>],
          desc: [<></>],
        },
      ],
      back: "borde_agenda back_dispar",
    },
    {
      dia: 2,
      hora: "09:15 – 10:00",
      actividad: [
        {
          personas: [
            {
              img: Jesus,
              nombre: [<span>Jesús Reyes Heroles </span>],
              cargo: [<span>Presidente de StructurA</span>],
            },
          ],
          titulo: [
            <span className="">
              BALANCE DE LAS RELACIONES BILATERALES ENTRE MÉXICO Y ESTADOS UNIDOS
            </span>,
          ],
          sub: [<span></span>],
          panelistas: [""],
          moderadores: [""],
          desc: [<></>],
        },
      ],
      back: "borde_agenda",
    },
    {
      dia: 2,
      hora: "10:00 – 10:30",
      actividad: [
        {
          personas: [
            {
              img: Pearson_Tom,
              nombre: [<span>Thomas O. Pearson</span>],
              cargo: [<span>Executive VicePresident SIOR, MBA</span>],
            },
          ],
          titulo: [
            <span className="">
              THE TRANSFORMATION OF INDUSTRIAL REAL ESTATE - WAREHOUSE DESIGN,
              E-COMMERCE AND THE SUPPLY CHAIN
            </span>,
          ],
          sub: [<span></span>],
          desc: [<></>],
          panelistas: [""],
          moderadores: [""],
        },
      ],
      back: "borde_agenda back_dispar",
    },
    {
      dia: 2,
      hora: "10:30 – 10:45",
      actividad: [
        {
          personas: [
            { img: "", nombre: [<span></span>], cargo: [<span></span>] },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [<span className="">Receso networking</span>],
          sub: [<span></span>],
          desc: [<></>],
        },
      ],
      back: "borde_agenda",
    },
    {
      dia: 2,
      hora: "10:45 – 11:30",
      actividad: [
        {
          personas: [""],
          panelistas: [
            {
              img: david_gutierrez_muguerza,
              nombre: [<span>David Gutiérrez Muguerza</span>],
              cargo: [
                <span>Director General DeAcero / Presidente de CANACERO</span>,
              ],
            },
            {
              img: Maximo_Vedoya,
              nombre: [<span>Maximo Vedoya</span>],
              cargo: [<span>CEO Ternium</span>],
            },
            {
              img: Yuri_de_los_Santos,
              nombre: [<span>Yuri de los Santos Llamas</span>],
              cargo: [<span>VP Segmento Construcciones CEMEX</span>],
            },
          ],
          moderadores: [
            {
              img: FJSolares,
              nombre: [<span>Francisco Javier Solares Alemán</span>],
              cargo: [
                <span>
                  {" "}
                  Presidente Nacional de la Cámara Mexicana de la Industria de la
                  Construcción (CMIC)
                </span>,
              ],
            },
          ],
          titulo: [
            <span className="">
              ¿QUÉ SE PUEDE ESPERAR PARA EL SECTOR DE LOS MATERIALES DE
              CONSTRUCCIÓN”
            </span>,
          ],
          sub: [""],
          desc: [""],
        },
      ],
      back: "borde_agenda back_dispar",
    },
    {
      dia: 2,
      hora: "11:30 – 11:35",
      actividad: [
        {
          personas: [
            { img: "", nombre: [<span></span>], cargo: [<span></span>] },
          ],
          panelistas: [""],
          moderadores: [""],
          titulo: [
            <span className="">
              FIRMA DE CONVENIO DE COLABORACIÓN AMPIP - CMIC
            </span>,
          ],
          sub: [<span></span>],
          desc: [<></>],
        },
      ],
      back: "borde_agenda",
    },
    {
      dia: 2,
      hora: "11:35 – 12:05",
      actividad: [
        {
          personas: [
            {
              img: Carlos_Salazar,
              nombre: [<span>Carlos Salazar Lomelín</span>],
              cargo: [<span>Empresario</span>],
            },
          ],
          titulo: [<span className="">MÉXICO Y SUS DILEMAS</span>],
          sub: [<span></span>],
          panelistas: [""],
          moderadores: [""],
          desc: [<></>],
        },
      ],
      back: "borde_agenda",
    },
    {
      dia: 2,
      hora: "12:05 – 13:00",
      actividad: [
        {
          personas: [""],
          titulo: [
            <span className="">
              EL FUTURO DE LAS CADENAS DE VALOR Y LA INNOVACIÓN EN LA INDUSTRIA
              MANUFACTURERA
            </span>,
          ],
          sub: [""],
          desc: [<></>],
          panelistas: [
            {
              img: Francisco_Gonzalez_Diaz,
              nombre: [<span>Francisco N. González Díaz </span>],
              cargo: [
                <span>
                  Presidente Ejecutivo de la Industria Nacional de Autopartes
                  (INA)
                </span>,
              ],
            },
            {
              img: Marco_Cosio,
              nombre: [<span>Marco Cosío Gaggero </span>],
              cargo: [
                <span>
                  Vicepresidente Smart Infraestructure Siemens, México,
                  Centroamérica y El Caribe
                </span>,
              ],
            },
            {
              img: humberto_fernandez_montes,
              nombre: [<span>Humberto Fernández Montes</span>],
              cargo: [
                <span>
                  Jefe del Departamento de Desarrollo de Piezas, KIA México
                </span>,
              ],
            },
          ],
          moderadores: [
            {
              img: Jose_Ma_Garza,
              nombre: [<span>José María Garza Treviño </span>],
              cargo: [<span>Grupo GP</span>],
            },
          ],
        },
      ],
      back: "borde_agenda back_dispar",
    },
    {
      dia: 2,
      hora: "13:00 – 14:00",
      actividad: [
        {
          personas: [
            { img: receso, nombre: [<span></span>], cargo: [<span></span>] },
          ],
          titulo: [<span className="">Aperitivo y networking</span>],
          sub: [<span></span>],
          panelistas: [""],
          moderadores: [""],
          desc: [<></>],
        },
      ],
      back: "borde_agenda",
    },
    {
      dia: 2,
      hora: "14:00",
      actividad: [
        {
          personas: [
            { img: receso, nombre: [<span></span>], cargo: [<span></span>] },
          ],
          titulo: [<span className="">COMIDA</span>],
          sub: [<span></span>],
          panelistas: [""],
          moderadores: [""],
          desc: [<></>],
        },
      ],
      back: "borde_agenda back_dispar",
    },
    {
      dia: 2,
      hora: "15:00 - 15:45",
      actividad: [
        {
          personas: [
            {
              img: Idelfonso_Guajardo,
              nombre: [<span>Ildefonso Guajardo Villarreal </span>],
              cargo: [
                <span>
                  Diputado Federal por Nuevo León y Diputado Local por Monterrey
                </span>,
              ],
            },
          ],
          titulo: [<span className="">EL FUTURO T-MEC</span>],
          sub: [<span></span>],
          panelistas: [""],
          moderadores: [""],
          desc: [<></>],
        },
      ],
      back: "borde_agenda",
    },
    {
      dia: 2,
      hora: "16:00",
      actividad: [
        {
          personas: [
            {
              img: sergio_arguelles_,
              nombre: [<span>Sergio Argüelles González</span>],
              cargo: [<span>Presidente AMPIP</span>],
            },
          ],
          titulo: [<span className="">CIERRE DEL EVENTO</span>],
          sub: [<span></span>],
          panelistas: [""],
          moderadores: [""],
          desc: [<></>],
        },
      ],
      back: "borde_agenda",
    },
    {
      dia: 3,
      hora: "09:00 - 12:00",
      actividad: [
        {
          personas: [
            { img: "", nombre: [<span></span>], cargo: [<span></span>] },
          ],
          titulo: [<span className="">VISITAS A INDUSTRIAS</span>],
          sub: [<span></span>],
          desc: [<></>],
          panelistas: [""],
          moderadores: [""],
        },
      ],
      back: "borde_agenda",
    },
  ];

  const ordenes = [
    {orden: 1, texto:[<>
    <p className="titulo_agenda">Comité de Promoción</p>
    <ol className="desc_agenda" type="1">
      <li>Bienvenida Presidente del Comité – <span className="color_aqua">José Luis Benitez</span></li>
      <li>Autopresentación de los asistentes</li>
      <li>Presentación de plan de trabajo y acciones relevantes
        <ol type="a">
          <li>Plan de trabajo 2022</li>
        </ol>
      </li>
      <li>Seguimiento y avance de acuerdos
        <ol type="a">
          <li>Información
            <ol type="i">
              <li>Materiales actualizados</li>
              <li>Sitio web</li>
            </ol>
          </li>
          <li>Resumen de leads AMPIP</li>
          <li>Ecosistema de promoción AMPIP</li>
          <li>Eventos
            <ol type="i">
              <li>Promoción con cuerpo diplomático - 20 de octubre 2022</li>
            </ol>
          </li>
        </ol>
      </li>
      <li>Nuevos temas:
        <ol type="a">
          <li>Encuesta sobre percepción y tendencias de la IED en México, 2022 - 2023</li>
        </ol>
      </li>
      <li>Propuestas de eventos y capacitación</li>
    </ol>
    </>]},
    {orden: 2, texto:[<>
      <p className="titulo_agenda">Comité ASG</p>
      <ol className="desc_agenda" type="1">
        <li>Bienvenida Presidente del Comité – <span className="color_aqua">Karen Mora</span></li>
        <li>Autopresentación de los asistentes</li>
        <li>Presentación nuevo Secretario Técnico - <span className="color_aqua">Diego Colunga</span></li>
        <li>Presentación de plan de trabajo y acciones <span className="color_aqua">relevantes – Karen Mora</span>
          <ol className="q">
            <li>Plan de trabajo 2022</li>
            <li>Acciones relevantes
              <ol type="i">
                <li>Resumen del Sondeo ASG y Sustentabilidad</li>
                <li>Adhesión AMPIP al Pacto Mundial</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>Seguimiento y avance de acuerdos
          <ol type="a">
            <li>Perfil de puesto responsable ASG en parques (técnico y directivo) - <span className="color_aqua">Mario del Bosque</span></li>
            <li>Guía para homologación de conocimientos - <span className="color_aqua">Carlos Viesca</span></li>
            <li>Directorio - mapa del ecosistema AMPIP - <span className="color_aqua">Diego Colunga</span></li>
            <li>Reporte de los seminarios:
              <ol>
                <li>Certificaciones Leed, Well, Edge - <span className="color_aqua">Karen Mora</span></li>
                <li>Código de red - <span className="color_aqua">Diego Colunga</span></li>
                <li>Programa Nacional de Auditoría Ambiental PROFEPA - <span className="color_aqua">Rodolfo Morales</span></li>
              </ol>
            </li>
          </ol>
        </li>
        <li>Nuevos temas:
          <ol>
            <li>Presentación proyecto Mariposa Monarca – <span className="color_aqua">David Eaton</span> - 15 min</li>
            <li>Compromiso social AMPIP – <span className="color_aqua">Karen Mora</span> – 5 min
              <ol>
                <li>Se reciben propuestas hasta el 15 de noviembre</li>
                <li>Reunión noviembre, Comité ASG decide acción social para 2023</li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>
    </>]},
    {orden: 3, texto:[<>
      <p className="titulo_agenda">Comité de Infraestructura y Seguridad</p>
      <ol className="desc_agenda" type="1">
        <li>Bienvenida Presidente del Comité - <span className="color_aqua">Daniel Jaimes</span></li>
        <li>Autopresentación de los asistentes</li>
        <li>Seguimiento y avance de acuerdos - <span className="color_aqua">Lizbeth Contreras</span>
          <ol type="a">
            <li>Reporte general de las reuniones de trabajo del Comité en el periodo mayo-agosto</li>
            <li>Presentación de la nueva versión del documento “Criterios mínimos en seguridad con los que deben contar los parques industriales” (antes Parque Industrial Seguro)</li>
            <li>Presentación del directorio con nuevos consultores expertos en materia de seguridad</li>
            <li>Estatus de la actualización de la Norma Mexicana de Parques Industriales
              <ol type="i">
                <li>Situación actual del comité (CTNNPI)</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>Logística
          <ol type="a">
            <li>Sugerencias de lineas de acción por parte de los asistentes</li>
            <li>Actividades sugeridas</li>
          </ol>
        </li>
        <li>Propuestas de eventos y capacitación</li>
      </ol>
      </>]}
  ]

  let visualizar_agenda = conferencia
    .filter((item) => {
      if (item.dia === dia) {
        return true;
      } else {
        return false;
      }
    })
    .map((da, index) => {
      return (
        <Row
          justify="center"
          align="middle"
          style={{ padding: "15px 0px" }}
          className={da.back}
        >
          <Col xs={24} md={4}>
            <p className="hora_agenda">{da.hora}</p>
          </Col>
          <Col xs={24} md={20}>
            {da.actividad.map((datos) => {
              return (
                <>
                  <Row align="middle">
                    <Col xs={20} md={10} style={{ paddingLeft: "10px" }}>
                      {datos.personas != "" ? (
                        <>
                          {datos.personas.map((item) => {
                            return (
                              <>
                                <Row align="middle">
                                  <Col xs={8} md={6}>
                                    {item.img != "" ? (
                                      <img
                                        src={item.img}
                                        style={{
                                          width: "70px",
                                          margin: "3px 0px",
                                        }}
                                        alt={item.nombre}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </Col>
                                  <Col xs={16} md={18}>
                                    <p className="nombre_agenda">
                                      {item.nombre}
                                    </p>
                                    <p className="cargo_nombre">{item.cargo}</p>
                                  </Col>
                                </Row>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}

                      {datos.moderadores != "" ? (
                        <>
                          {datos.moderadores.map((item) => {
                            return (
                              <>
                                <p
                                  style={{
                                    color: "#ffffff",
                                    textAlign: "left",
                                    fontSize: "18px",
                                  }}
                                >
                                  Moderador:
                                </p>
                                <Row align="middle">
                                  <Col xs={12} md={6}>
                                    {item.img != "" ? (
                                      <img
                                        src={item.img}
                                        style={{
                                          width: "70px",
                                          margin: "3px 0px",
                                        }}
                                        alt={item.nombre}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </Col>
                                  <Col xs={12} md={18}>
                                    <p className="nombre_agenda">
                                      {item.nombre}
                                    </p>
                                    <p className="cargo_nombre">{item.cargo}</p>
                                  </Col>
                                </Row>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}

                      {datos.panelistas != "" ? (
                        <>
                          <p
                            style={{
                              color: "#ffffff",
                              textAlign: "left",
                              fontSize: "18px",
                            }}
                          >
                            Panelistas:
                          </p>
                          {datos.panelistas.map((item) => {
                            return (
                              <>
                                <Row align="middle">
                                  <Col xs={12} md={6}>
                                    {item.img != "" ? (
                                      <img
                                        src={item.img}
                                        style={{
                                          width: "70px",
                                          margin: "3px 0px",
                                        }}
                                        alt={item.nombre}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </Col>
                                  <Col xs={12} md={18}>
                                    <p className="nombre_agenda">
                                      {item.nombre}
                                    </p>
                                    <p className="cargo_nombre">{item.cargo}</p>
                                  </Col>
                                </Row>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>

                    <Col xs={24} md={14}>
                      <p className="titulo_agenda">{datos.titulo}</p>
                      {datos.sub != "" && (
                        <p className="sub_agenda">{datos.sub}</p>
                      )}
                      {datos.desc != "" && (
                        <p className="desc_agenda">{datos.desc}</p>
                      )}
                    </Col>
                  </Row>
                </>
              );
            })}
          </Col>
        </Row>
      );
    });

    let ver_texto_modal = ordenes
    .filter((item) => {
      if (ver_order === item.orden) {
        return true;
      } else {
        return false;
      }
    })
    .map((seccion, index) => {
      return (
        <Col xs={24}>
          {seccion.texto}
        </Col>
      );
    });

  return (
    <Row className="back_titulo_agenda">
      <Col span={20} offset={2}>
        <Row justify="center" align="middle" style={{ position: "relative" }}>
          <Col span={18} style={{ position: "relative" }}>
            <img src={banner} style={{ width: "100%" }} />
          </Col>
          <Col
            span={24}
            offset={0}
            style={{ position: "absolute", width: "100%" }}
          >
            <p
              className="color_blanco textos_titulos_secciones"
              style={{ textAlign: "center" }}
            >
              {idioma === "es" ? "Agenda" : "Schedule"}
            </p>
          </Col>
        </Row>
      </Col>

      <Col xs={{ span: 22, offset: 1 }} md={{ span: 16, offset: 4 }}>
        <p className="color_aqua">HORA CIUDAD DE MÉXICO</p>
        <br />
        <Row>
          <Col
            xs={{ span: 20, offset: 2 }}
            md={{ span: 8, offset: 0 }}
            onClick={() => setDia(1)}
            className={
              dia === 1 ? "back_aqua puntero" : "back_tranparencia puntero"
            }
          >
            <p className="color_blanco tex_agenda_dia">
              <strong>Día</strong>{" "}
              <strong className={dia === 1 ? "color_blanco" : "color_aqua"}>
                01
              </strong>
              <br />
              <span className="fecha_text_agenda">7 septiembre, 2022</span>
            </p>
          </Col>
          <Col
            xs={{ span: 20, offset: 2 }}
            md={{ span: 8, offset: 0 }}
            onClick={() => setDia(2)}
            className={
              dia === 2 ? "back_aqua puntero" : "back_tranparencia puntero"
            }
          >
            <p className="color_blanco tex_agenda_dia">
              <strong>Día</strong>{" "}
              <strong className={dia === 2 ? "color_blanco" : "color_aqua"}>
                02
              </strong>
              <br />
              <span className="fecha_text_agenda">8 septiembre, 2022</span>
            </p>
          </Col>
          <Col
            xs={{ span: 20, offset: 2 }}
            md={{ span: 8, offset: 0 }}
            onClick={() => setDia(3)}
            className={
              dia === 3 ? "back_aqua puntero" : "back_tranparencia puntero"
            }
          >
            <p className="color_blanco tex_agenda_dia">
              <strong>Día</strong>{" "}
              <strong className={dia === 3 ? "color_blanco" : "color_aqua"}>
                03
              </strong>
              <br />
              <span className="fecha_text_agenda">9 septiembre, 2022</span>
            </p>
          </Col>
        </Row><br />
        <p className="color_aqua">VESTIMENTA: CASUAL DE NEGOCIOS</p>
      </Col>
      <Col xs={{ span: 22, offset: 1 }} md={{ span: 20, offset: 2 }}>
        
        {dia === 2 ? (
          <><br />
            <p className="tamano_14 color_aqua">
              Safi Metropolitan, Gran Salón
            </p>
          </>
        ) : (
          <></>
        )}
        <br />
        <br />
        {visualizar_agenda}

        <br />
        <br />
        {/*<a href={pdf_espanol} className='btn_aqua_agenda' download>DESCARGAR AGENDA</a>
          <span style={{width:'40px'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <p style={{textAlign:'right'}}><a href={pdf_ingles} className='btn_aqua_agenda' download>AGENDA INGLES</a></p>*/}
        <br />
        <br />
      </Col>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        title={false}
        closeIcon={<CloseOutlined style={{ color: "#ffffff" }} />}
      >
        {ver_texto_modal}
      </Modal>
    </Row>
  );
};
export default Agenda;
