import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import '../../assets/css/membresia.css';


import img_copachisa from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/contruccion/copachisa.png';
import img_crocsa from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/contruccion/crocsa.png';
import img_empire from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/contruccion/empire.png';
import img_hermosillo from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/contruccion/hermosillo.png';
import img_isosundu from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/contruccion/isosundu.png';
import img_kingspan from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/contruccion/kingspan.png';
import img_solana from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/contruccion/solana.png';
import img_hikvision from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/telecomunicaciones/hikvision.png';
import img_top from '../../assets/images/segunda_reunion/MEMEBRESIA/logos/inmoviliario/top_.png';
import img_cemex from '../../assets/images/tercera_reunion/MEMBRESIA/logos_cemex.jpg';

import { FastBackwardOutlined, LeftOutlined } from '@ant-design/icons';

const logo = [
    { nombre: '', img: img_cemex, link: '', url: '' },
    { nombre: '', img: img_copachisa, link: '', url: '' },
    { nombre: '', img: img_crocsa, link: '', url: '' },
    { nombre: '', img: img_empire, link: '', url: '' },
    { nombre: '', img: img_hermosillo, link: '', url: '' },
    { nombre: '', img: img_hikvision, link: '', url: '' },
    { nombre: '', img: img_isosundu, link: '', url: '' },
    { nombre: '', img: img_kingspan, link: '', url: '' },
    { nombre: '', img: img_solana, link: '', url: '' },
    { nombre: '', img: img_top, link: '', url: '' },
];


const Construccion = (props) => {
    const ver_logos = logo.map(item => {
        return (<>
            <Col xs={10} lg={5} className="contenedor_targeta_membresia">
                <Row justify='center'>
                    <Col span={20}>
                        <img alt={item.nombre} src={item.img} style={{ width: '100%' }} />
                    </Col>
                    <Col span={0}>
                        <p className='nombre_membresia'>{item.nombre}</p>
                        <a href={item.url} className="link_membresia" target='_blank'>{item.link}</a>
                    </Col>
                </Row>
            </Col>
        </>)
    }
    );
    const { idioma } = props;

    return (
        <Row>
            <Col xs={0} md={24}>
                <br /><br /><br /><br /><br /><br />
            </Col>
            <Col xs={24} md={0}>
                <br /><br /><br />
                <br />
            </Col>
            <Col span={24}>
                <Row align='middle'>
                    <Col xs={{span:4, offset:1}} lg={{span:4, offset:2}}>
                        <Link className='regresar_membresia' to='/#membresia'><LeftOutlined />Regresar</Link>
                    </Col>
                    <Col xs={{span:16,offset:0}} lg={{span:12,offset:0}}>
                        <p className='titulo_tipo_membresia'>AFILIADOS SECTORIALES</p>
                    </Col>
                </Row>
            </Col>
            <Col span={24} offset={0} className='contenedor_membresias_interior'>
                <Row justify='center' align='middle'>
                    <Col xs={20} md={8}>
                        <p className='titulo_membresia'>Construcción y Seguridad</p>
                        <br />
                    </Col>
                    <Col span={20}>
                        <Row justify="space-around">
                            {ver_logos}
                        </Row>
                    </Col>
                    <Col xs={0} md={20}>
                        <br /><br />
                    </Col>
                    <Col xs={24} md={0}>
                        <br /><br /><br /><br />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
export default Construccion;