import React from 'react';
import { Row, Col, Card, Form, Input, notification, Button } from 'antd';
import { Redirect, Link } from 'react-router-dom';
import Firebase from '../../utils/firebaseConfig';
import '../../assets/css/registro.css';


const app = new Firebase();

const mensaje = (tipo, titulo, texto) =>{
    notification[tipo]({
        message: titulo,
        description: texto,
      });
}


const RecuperarContrasena = (props) => {

    const { correo } = props.location.state;

    console.log(correo);

    const onFinish = values => {
        app.app.auth().sendPasswordResetEmail(values.correo).then(() => {
            mensaje('success','Hemos enviado un correo a tu bandeja, por favor sigue las intrucciones indicadas.');
        },(err) =>{
            if('There is no user record corresponding to this identifier. The user may have been deleted.' === err.message){
                mensaje('warning','El correo electrónico no se encuentra registrado');
            }else{
                console.log(err);
                mensaje('error','Tenemos problemas para recuperar tu contraseña');
            }
            
        });
      };

    return(
        <Row className="contenedor_registro">
            <Col xs={{span:20, offset:2}} md={{span:10, offset:7}}>
                <br /><br /><br /><br /><br /><br />
            <Card title="Recuperar contraseña" extra={<Link to="/login">Volver</Link>} style={{ width: '100%' }}>
            <Form
      name="basic"
      initialValues={{
          correo:correo,
        remember: true,
      }}
      onFinish={onFinish}
      layout="vertical"
    >
      <Form.Item
        label="Correo electrónico"
        name="correo"
        
        rules={[
          {
            required: true,
            message: 'Por favor ingresa un correo electrónico',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
          <br />
        <Button type="primary" htmlType="submit">
          Enviar
        </Button>
      </Form.Item>
    </Form>
            </Card>
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            </Col>
        </Row>
    );

}

export default RecuperarContrasena;
