import React, { useEffect, useContext, useState, useRef } from "react";
import { Redirect } from 'react-router-dom';

import { Row, Col, Radio, Spin, Modal, Button , notification} from "antd";
import { Auth } from "../../utils/AuthContext";
import { withRouter } from "react-router";
import Firebase from '../../utils/firebaseConfig';
import Live from '../conferencias/Live';

import banner from '../../assets/images/segunda_reunion/bannerprincipal_home_ampip.jpg';

import banner_movil from '../../assets/images/segunda_reunion/2reunion_ampip_sitio_movil_.jpg';

import logo_banner_ergistro from '../../assets/images/segunda_reunion/2reunion_ampip_sitio_LOGO-.png';
import { Link } from "react-router-dom";

const nombre_evento = 'segunda-reunion';
const app = new Firebase();

const success = () => {
    mensaje('success','Bienvenido','');
  };

  const mensaje = (tipo,titulo, texto) =>{

    notification[tipo]({
      message: titulo,
      description: texto,
    });
  
  }
const Entrada = ({ history }) => {
    
    const { usuario, datos } = useContext(Auth);

    const [sin_login, setSinLogin] = useState(false);

    useEffect(() => {
        
        if (!usuario) {
            mensaje('warning','Advertencia','Para entrar a esta sección necesitas haber iniciado sesión.');
            setSinLogin(true);
        }
        
    }, [history, usuario, datos]);

    useEffect(() => {
        if (usuario) {
            console.log('datos entrada : ', usuario);
        } else {
            console.log('vacio');
        }
    }, [usuario]);

    return (
        sin_login ? 

            <>
            <Redirect to="/" />
            </>
        :
        <>
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={24}>
                            <Row style={{ position: 'relative' }}>
                                <Col xs={0} md={24} style={{ position: 'relative' }}>
                                    <img src={banner} style={{ width: '100%' }} />
                                </Col>
                                <Col xs={24} md={0} style={{ position: 'relative' }}>
                                    <img src={banner_movil} style={{ width: '100%' }} />
                                </Col>
                                <Col span={24} offset={0} className="contenedor_banner_menu" style={{ position: 'absolute', width: '100%' }}>
                                    <Row justify='center'>
                                        <Col xs={24} md={0}>
                                            <br /><br /><br />
                                        </Col>
                                        <Col span={14}>
                                            <img src={logo_banner_ergistro} style={{ width: '100%' }} />
                                        </Col>
                                        
                                        <Col xs={24}>
                                            <br /><br />
                                            <p className='text_banner'>Miércoles 8 de junio de 2022 <span className='etiqueta_banner'>VIRTUAL</span></p>
                                            <br /><br />
                                        </Col>
                                        <Col xs={24}><br />
                                            <a href="https://us02web.zoom.us/j/89167801269?pwd=eUoyeVU4RURSaGpMdmlhY09PeExGZz09" className='btn_azul' target="_blank">DA CLIC PARA ENTRAR A TRANSMSIÓN</a>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </Col>
            </Row>
        </>
    );

}

export default Entrada;