import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import '../../assets/css/hospedaje.css';

import banner from '../../assets/images/tercera_reunion/HOSPEDAJE/hospedaje_titulo.png';

import oficial_sencilla from '../../assets/images/tercera_reunion/HOSPEDAJE/sedeof_sencilla.png';
import oficial_doble from '../../assets/images/tercera_reunion/HOSPEDAJE/sedeof_doble.png';
import alterna_sencilla from '../../assets/images/tercera_reunion/HOSPEDAJE/sedeal_sencilla.png';
import alterna_doble from '../../assets/images/tercera_reunion/HOSPEDAJE/sedeal_doble.png';

const Hospedaje = (props) => {
    const [agenda, setRevista] = useState(false);
    const { idioma } = props;


    return (
        <Row className='back_hospedaje'>
            <Col span={20} offset={2}>
                <Row justify='center' align='middle' style={{ position: 'relative' }}>
                    <Col span={18} style={{ position: 'relative' }}>
                        <img src={banner} style={{ width: '100%' }} />
                    </Col>
                    <Col span={24} offset={0} style={{ position: 'absolute', width: '100%' }}>
                        <p className="color_azul textos_titulos_seccion" style={{ textAlign: 'center' }}>Hospedaje</p>
                    </Col>
                </Row>
            </Col>

            <Col span={16} offset={4}>
                <Row justify='center'>
                    <Col xs={20} md={12}>
                        <p className='color_azul text_safi'>SAFI METROPOLITAN</p>
                        <p className='color_azul text_safi_ubicacion'>Av. Lázaro Cárdenas 2400, Valle Oriente, 66260 San Pedro Garza García, N.L.
                            <br />
                            <a href="https://safihotel.com/" target="_blank" className='color_aqua'>safihotel.com</a>
                        </p>
                    </Col>
                    <Col xs={20} md={12}>
                        <p className='color_azul text_safi'>SAFI VALLE</p>
                        <p className='color_azul text_safi_ubicacion'>Diego Rivera 555, Valle Oriente, 66260 San Pedro Garza García, N.L.
                            <br />
                            <a href="https://safihotel.com/" target="_blank" className='color_aqua'>safihotel.com</a>
                        </p>
                    </Col>
                </Row>
            </Col>
            <Col span={20} offset={2}>
                <Row>
                    <Col xs={{span:24}} md={{span:6}}>
                        <img src={oficial_sencilla} className="img_100" />
                    </Col>
                    <Col xs={{span:24}} md={{span:6}}>
                        <img src={oficial_doble} className="img_100" />
                    </Col>
                    <Col xs={{span:24}} md={{span:6}}>
                        <img src={alterna_sencilla} className="img_100" />
                    </Col>
                    <Col xs={{span:24}} md={{span:6}}>
                        <img src={alterna_doble} className="img_100" />
                    </Col>
                </Row>
            </Col>
            <Col span={20} offset={2}><br />
                <p className='tamano_16'><strong>*Plan Europeo.</strong> No incluye impuestos, ni propinas</p>
                <p className='tamano_12'>Tarifas Preferenciales<br />
                    <strong>Código ASMP</strong>
                </p>
            </Col>

        </Row>
    )
}
export default Hospedaje;