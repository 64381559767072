import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { scroller } from 'react-scroll';


import usuario_img from '../../assets/images/evento/1_REGISTRO/icono-usuario.png';
import apellido_img from '../../assets/images/evento/1_REGISTRO/icono-password.png';
import img_entrar_recepcion from '../../assets/images/registro/registrate_masterclass_blanco.png';
import pago_paypal from '../../assets/images/registro/LOGO_paypal.png';

const RegistroCompleto = (props) => {

    const { correo, password, tipo_registro, nombre = '', idioma } = props;

    useEffect(() => {
        scroller.scrollTo('header');
    }, []);

    return (
        <Row align="middle">
            <Col span={22} offset={1}>


                {/*tipo_registro === 'presencial' ?*/}
                
                <br /><br />
                <p className="textos_titulos_secciones" style={{ textAlign: 'center' }}>¡Gracias por tu registro!</p>
                
                    <p style={{ textAlign: 'center' }}>
                        <span className="texto-breve color_aqua"><strong>{nombre}</strong></span>
                        <br />
                        <span className="texto-breve color_blanco ">En breve recibirás un correo de confirmación a la siguiente dirección: </span><br /><br />

                        <span className="correo-azul color_aqua "> <strong>{correo}</strong> </span><br /> <br />
                        <span className="leyenda color_gris_claro ">Si no encuentras el mail de confirmación en tu bandeja de entrada, es posible que esté en la <br />carpeta de SPAM / NO DESEADOS.</span> <br /> <br />
                    </p>
                    </Col>
                    <Col span={12} offset={6}>
                    <p className='back_aqua'><br />
                        <span className="texto-breve color_blanco">
                            <strong>Monterrey, N.L.</strong><br /></span>
                        <strong className="texto-breve-direccion color_blanco">7 al 9 de septiembre 2022</strong>
                        <br /><br />
                    </p>

                </Col>
                    {/*:<>
                        
                        <p className="textos_titulos_secciones" style={{ textAlign: 'center' }}>{idioma === 'es' ? '¡Gracias por tu registro!' : 'Thank you for your registration!'}</p>
                        <br />
                        <p>

                            <span className="fondo back_azul texto_titulo_instruccions">{idioma === 'es' ? 'INSTRUCCIONES DE ACCESO' : 'ACCESS INSTRUCTIONS'}</span><br />

                        </p><br />

                        <Row >
                            <Col xs={{ span: 2, offset: 0 }} md={{ span: 1, offset: 5 }} lg={{ span: 1, offset: 6 }}>
                                <p style={{ textAlign: 'right', paddingLeft: '0%', paddingRight: '0%' }}>
                                    <span className="datos-azul color_blanco"><strong>1. </strong></span> </p>
                            </Col>
                            <Col xs={{ span: 22, offset: 0 }} md={{ span: 14, offset: 0 }} lg={{ span: 11, offset: 0 }} >
                                <p style={{ textAlign: 'left', paddingLeft: '1%', paddingRight: '0%' }}>
                                    <span className="datos-azul color_blanco">{idioma === 'es' ? 'Ingresa a' : 'Sign in to'} </span>
                                    <a className="datos-azul color_aqua" href='https://reuniondesocios.ampip.mx/transmision'>reuniondesocios.ampip.mx/transmision</a></p>
                            </Col>

                        </Row>
                        <Row>
                            <Col xs={{ span: 2, offset: 0 }} md={{ span: 1, offset: 5 }} lg={{ span: 1, offset: 6 }}>
                                <p style={{ textAlign: 'right', paddingLeft: '0%', paddingRight: '0%' }}>
                                    <span className="datos-azul color_blanco "><strong>2. </strong></span></p>
                            </Col>
                            <Col xs={{ span: 22, offset: 0 }} md={{ span: 14, offset: 0 }} lg={{ span: 11, offset: 0 }}>
                                <p style={{ textAlign: 'left', paddingLeft: '1%', paddingRight: '0%' }}>
                                    {idioma === 'es' ? 
                                    <span className="datos-azul color_blanco">Escribe tu <span className="datos-azul color_aqua "> usuario (correo electrónico)</span> en la casilla correspondiente.</span>
                                 : 
                                 <span className="datos-azul color_blanco">Write your <span className="datos-azul color_aqua "> username (email)</span> in the corresponding box.</span>}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 2, offset: 0 }} md={{ span: 1, offset: 5 }} lg={{ span: 1, offset: 6 }}>
                                <p style={{ textAlign: 'right', paddingLeft: '0%', paddingRight: '0%' }}>
                                    <span className="datos-azul color_blanco "><strong>3. </strong></span> </p>
                            </Col>
                            <Col xs={{ span: 22, offset: 0 }} md={{ span: 14, offset: 0 }} lg={{ span: 11, offset: 0 }}>
                                <p style={{ textAlign: 'left', paddingLeft: '1%', paddingRight: '0%' }}>
                                    {idioma === 'es' ? 
                                    <span className="datos-azul color_blanco">Repite el paso anterior con tu <span className="datos-azul color_aqua "> primer apellido.</span></span>
                                :
                                <span className="datos-azul color_blanco">Repeat the previous step with your <span className="datos-azul color_aqua "> first last name.</span></span>}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 2, offset: 0 }} md={{ span: 1, offset: 5 }} lg={{ span: 1, offset: 6 }}>
                                <p style={{ textAlign: 'right', paddingLeft: '0%', paddingRight: '0%' }}>
                                    <span className="datos-azul color_blanco "><strong>4. </strong></span> </p>
                            </Col>
                            <Col xs={{ span: 22, offset: 0 }} md={{ span: 14, offset: 0 }} lg={{ span: 11, offset: 0 }}>
                                <p style={{ textAlign: 'left', paddingLeft: '1%', paddingRight: '0%' }}>
                                    {idioma === 'es' ? 
                                    <span className="datos-azul color_blanco">Da clic en <span className="datos-azul color_aqua ">ingresar</span> y disfruta del evento.</span>
                                :
                                <span className="datos-azul color_blanco">Click <span className="datos-azul color_aqua ">enter</span> and enjoy the event.</span>}
                                    </p>
                            </Col>
                        </Row>



                        <br /><br />
                        <span className="fondo back_azul texto_titulo_instruccions">{idioma === 'es' ? 'TUS DATOS DE ACCESO' : 'YOUR ACCESS DATA'}</span>
                        <br /><br />

                        <Row align="middle">
                            <Col xs={{ span: 8, offset: 0 }} md={{ span: 2, offset: 7 }} >
                                <p >
                                    <img src={usuario_img} style={{ width: '50px' }} />
                                </p>
                            </Col>
                            <Col xs={{ span: 16, offset: 0 }} md={{ span: 7, offset: 0 }}>
                                <p className="datos-azul color_blanco" style={{ textAlign: 'left' }}>
                                    {idioma === 'es' ? 'Tu usuario (correo electrónico):' : 'Your username (email):'}
                                    <br />
                                    <span><strong>{correo}</strong></span>
                                </p>
                            </Col>
                        </Row>


                        <Row align="middle">
                            <Col xs={{ span: 8, offset: 0 }} md={{ span: 2, offset: 7 }}>
                                <p >
                                    <img src={apellido_img} style={{ width: '50px' }} />
                                </p>
                            </Col>
                            <Col xs={{ span: 16, offset: 0 }} md={{ span: 7, offset: 0 }}>
                                <p className="datos-azul color_blanco" style={{ textAlign: 'left' }} >
                                    {idioma === 'es' ?  'Tu primer apellido:' : 'Your first surname:'}
                                    <br />
                                    <span><strong>{password}</strong></span>
                                </p>
                            </Col>
                        </Row>



                        <br />
                        <p className="nota color_gris_oscuro ">{idioma === 'es' ? 'Nota: La transmisión se habilitará 30 minutos antes del evento.' : 'Note: The transmission will be enabled 30 minutes before the event.'}</p><br /> <br />
            </>*/}
                <Col span={22} offset={1}>
                <span className="color_blanco" style={{ fontSize: '30px' }}><strong>{idioma === 'es' ? '¡TE ESPERAMOS!' : 'WE WILL WAIT FOR YOU'}</strong></span>
                <br /><br />
                {/*<span className='leyenda'>Para cualquier información adicional, favor de dirigirse a Cecilia Toribio, Coordinadora de Eventos <a href="mailto:ctoribio@ampip.org.mx">ctoribio@ampip.org.mx</a></span>*/}
            </Col>
        </Row>
    );
}

export default RegistroCompleto;