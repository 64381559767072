import React, { useState, useContext, useEffect, useRef } from "react";
import RegistroCompleto from "./RegistroCompleto";
import "../../assets/css/login.css";
import { Auth } from "../../utils/AuthContext";

import banner from "../../assets/images/tercera_reunion/REGISTRO/banner_registro.jpg";
import fecha from "../../assets/images/tercera_reunion/HOME/icono_calendario.png";
import lugar from "../../assets/images/tercera_reunion/HOME/icono_ubicacion.png";

import logo_banner_ergistro from "../../assets/images/tercera_reunion/HOME/logo_3reunios_ampip.png";
import titulo_img from "../../assets/images/segunda_reunion/REGISTRO/registro_ampip.png";
import titulo_img_en from "../../assets/images/segunda_reunion/REGISTRO/register_-eng.png";

import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  notification,
  Modal,
  Spin,
  InputNumber,
} from "antd";
import Firebase from "../../utils/firebaseConfig";
import {
  mail_registro_confirmacion,
  existe_empresa,
} from "../../utils/ConexionServidor";

import { withRouter } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";

import img_crear from "../../assets/images/evento/1_REGISTRO/btn_registro.png";
import img_ayuda from "../../assets/images/tercera_reunion/REGISTRO/btn_problemasregsitro_.png";
import img_ayuda_en from "../../assets/images/tercera_reunion/REGISTRO/btn_problemasregsitro_.png";
import btn_ayuda from "../../assets/images/tercera_reunion/btn_whastapp.png";
import btn_ayuda_en from "../../assets/images/tercera_reunion/btn_whastapp.png";

import wts from "../../assets/images/registro/btn_problemasregistro.png";
import boton_ya_cuenta from "../../assets/images/temp/image200x100.png";

import LoginExiste from "../registro/LoginExiste";

const openNotificationWithIcon = (type, titulo, texto) => {
  notification[type]({
    message: titulo,
    description: texto,
    duration: 10,
  });
};

const nom_evento = "tercera-reunion-2022";

const { Option } = Select;

let correo_registrado = "",
  password_registrado = "",
  perfil_registrado = "";

const app = new Firebase();

const success = () => {
  openNotificationWithIcon("success", "Bienvenido", "");
};

const Registro = ({ setsignup, history, tipo_registro, idioma }) => {
  const [otro_medio, setOtroMedio] = useState(false);
  const [confirmacion, setConfirmacion] = useState(false);
  const [cargando_regsitro, setCargandoRegistro] = useState(false);
  const { usuario, datos } = useContext(Auth);
  const [mostrar_login, setMostrarLogin] = useState(false);
  const [reutilizar_datos, setReutilizarDatos] = useState(false);
  const [tipo_perfl, setTipoPerfil] = useState("");
  const [tipo_membresia, setTipoMembresia] = useState("");
  const [nombre_completo, setNombreCompleto] = useState("");
  const [empresa_repetida, setEmpresaRepetida] = useState(false);
  const [perfil_elegido, setPerfilElegido] = useState(false);

  const [form] = Form.useForm();

  const ref_form = React.createRef();
  const ref_organizacion = React.createRef();
  const ref_recaptcha = React.createRef();

  const elegirMedio = (value) => {
    console.log(value);

    if (value === "Otro") {
      setOtroMedio(true);
    } else {
      setOtroMedio(false);
    }
  };

  useEffect(() => {
    if (usuario) {
      app.app
        .auth()
        .signOut()
        .then(function () {})
        .catch(function (error) {
          // An error happened.
        });
    }
  }, []);

  useEffect(() => {
    setEmpresaRepetida(false);
  }, [tipo_perfl]);

  useEffect(() => {
    console.log("se reutiliza: ", reutilizar_datos);
    if (reutilizar_datos) {
      if (datos) {
        console.log("existen los datos");

        datos.nombre_curso = nom_evento;
        datos.apellidos = datos.ap_paterno + " " + datos.ap_materno;

        mail_registro_confirmacion(datos).then(({ data, status }) => {
          switch (status) {
            case 0:
              //delete values.aceptar_terminos;
              app.escribir(
                nom_evento + "/usuarios",
                undefined,
                datos,
                () => {}
              );

              openNotificationWithIcon("success", "", data);
              setConfirmacion(true);
              break;
            default:
              openNotificationWithIcon("warning", "", data);
              setConfirmacion(false);
              break;
          }
          //setCargandoRegistro(false);
          setMostrarLogin(false);
        });
        setReutilizarDatos(false);
      } else {
        console.log("no existen los dtos");
      }
    }
  }, [datos, reutilizar_datos]);

  const registrarLoginExiste = (medio) => {
    setReutilizarDatos(true);
  };

  const onFinish = async (values) => {
    if (empresa_repetida) {
      openNotificationWithIcon(
        "warning",
        "YA EXISTE UN REGISTRO PARA ESTA EMPRESA",
        "Le recordamos que el evento presencial esta restringido a un registro por empresa, por lo que le sugerimos hacer el resto de sus inscipciones en el apartado “REGISTRO A EVENTO VIRTUAL”"
      );
      return;
    }

    setCargandoRegistro(true);

    let recaptcha = "sin-recaptcha"; //ref_recaptcha.current.getValue();

    values.correo = values.correo.toLowerCase();
    values.idioma = idioma;

    const { correo } = values;

    setNombreCompleto(values.nombre + " " + values.ap_paterno);

    let contra = values.password;

    correo_registrado = correo;

    values.nombre_curso = nom_evento;

    values.apellidos = values.ap_paterno;

    values["g-recaptcha-response"] = recaptcha;

    const acentos = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
    };
    values.pass = values.ap_paterno
      .split("")
      .map((letra) => acentos[letra] || letra)
      .join("")
      .toString()
      .toLowerCase();

    password_registrado = values.pass;
    perfil_registrado = values.perfil;

    values.tipo_entrada = "presencial";

    mail_registro_confirmacion(values).then(({ data, status }) => {
      switch (status) {
        case 0:
          console.log(values);
          delete values.medios;
          delete values.pass;
          delete values.aceptar_terminos;
          //delete values.aceptar_networking;
          delete values.comite_infraestructura;
          delete values.comite_asg;
          delete values.comite_promocion;

          delete values.otro_procedencia;

          openNotificationWithIcon("success", "", data);
          setConfirmacion(true);
          break;
        default:
          openNotificationWithIcon("warning", "", data);
          setConfirmacion(false);
          break;
      }
      setCargandoRegistro(false);
    });
  };

  const revisarEmpresa = (empresa) => {
    if (tipo_registro === "presencial") {
      existe_empresa({ empresa: empresa }).then(({ data }) => {
        if (data === 1) {
          openNotificationWithIcon(
            "warning",
            "YA EXISTE UN REGISTRO PARA ESTA EMPRESA",
            "Le recordamos que el evento presencial esta restringido a un registro por empresa, por lo que le sugerimos hacer el resto de sus inscipciones en el apartado “REGISTRO A EVENTO VIRTUAL”"
          );
          setEmpresaRepetida(true);
        } else {
          setEmpresaRepetida(false);
        }
      });
    }
  };

  const revisarPErfil = (perfil) => {
    if (perfil !== "") {
      setPerfilElegido(true);
    }
  };

  return (
    <>
      <Spin tip="Cargando..." spinning={cargando_regsitro}>
        <Row style={{ backgroundColor: "#1d1f43" }}>
          <Col span={24}>
            <Row
              justify="center"
              align="middle"
              style={{ position: "relative" }}
            >
              <Col span={24} style={{ position: "relative" }}>
                <img
                  src={idioma === "es" ? banner : banner}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col
                xs={14}
                md={14}
                style={{ position: "absolute", width: "100%" }}
              >
                <img src={logo_banner_ergistro} style={{ width: "100%" }} />
                <Row>
                  <Col xs={0} md={12}>
                    <p className="text_banner">
                      <img src={fecha} style={{ width: "40px" }} /> 7 al 9 de
                      septiembre 2022
                    </p>
                  </Col>
                  <Col xs={0} md={12}>
                    <p className="text_banner">
                      <img src={lugar} style={{ width: "40px" }} /> Monterrey,
                      N.L.
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          {confirmacion ? (
            <Col span={24}>
              <RegistroCompleto
                correo={correo_registrado}
                password={password_registrado}
                tipo_registro={tipo_registro}
                nombre={nombre_completo}
                idioma={idioma}
              />
            </Col>
          ) : (
            <>
              <Col xs={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }}>
                <Form
                  className="login-form"
                  ref={ref_form}
                  form={form}
                  layout="vertical"
                  name="basic"
                  initialValues={{
                    remember: true,
                    organizacion: "",
                  }}
                  onFinish={onFinish}
                >
                  <Row>
                    <Col span={20} offset={2}>
                      <Row
                        justify="center"
                        align="middle"
                        style={{ position: "relative" }}
                      >
                        <Col span={18} style={{ position: "relative" }}>
                          <img
                            src={idioma === "es" ? titulo_img : titulo_img_en}
                            style={{ width: "100%" }}
                          />
                        </Col>
                        <Col
                          span={24}
                          offset={0}
                          style={{ position: "absolute", width: "100%" }}
                        >
                          <p
                            className="color_blanco textos_titulos_secciones"
                            style={{ textAlign: "center" }}
                          >
                            {idioma === "es" ? "Registro" : "Register"}
                          </p>
                        </Col>
                      </Row>
                    </Col>

                    <Col
                      xs={{ span: 24, offset: 0 }}
                      md={{ span: 11, offset: 0 }}
                    >
                      <Form.Item
                        className="nom-label"
                        name="nombre"
                        label={
                          <span className="labels ">
                            {idioma === "es" ? "Nombre(s):" : "Name:"}
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "red" }} className="">
                                {idioma === "es"
                                  ? "Ingresa tu nombre"
                                  : "Enter your name"}
                              </span>
                            ),
                          },
                        ]}
                      >
                        <input
                          className="inputs"
                          autoComplete="nope"
                          autocomplete="nope"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      md={{ span: 11, offset: 2 }}
                    >
                      <Form.Item
                        className="nom-label"
                        name="ap_paterno"
                        label={
                          <span className="labels ">
                            {idioma === "es"
                              ? "Apellido Paterno:"
                              : "Last Name"}
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "red" }} className="">
                                {idioma === "es"
                                  ? "Ingresa tu apellido paterno"
                                  : "Enter your father's last name"}
                              </span>
                            ),
                          },
                        ]}
                      >
                        <input
                          className="inputs"
                          autoComplete="nope"
                          autocomplete="nope"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        className="nom-label"
                        name="correo"
                        label={
                          <span className="labels ">
                            {idioma === "es"
                              ? "Correo electrónico corporativo:"
                              : "Corporate email:"}
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "red" }} className="">
                                {idioma === "es"
                                  ? "Ingresa un correo válido"
                                  : "Enter a valid email"}
                              </span>
                            ),
                          },
                        ]}
                      >
                        <input
                          className="inputs"
                          autoComplete="nope"
                          autocomplete="nope"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        className="nom-label"
                        name="perfil"
                        label={
                          <span className="labels ">
                            Perfil{" "}
                            <small>
                              (Da clic en la flecha para seleccionar la opción
                              deseada)
                            </small>
                            :
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "red" }} className="">
                                {idioma === "es"
                                  ? "Selecciona un perfil"
                                  : "Select a profile"}
                              </span>
                            ),
                          },
                        ]}
                      >
                        <Select
                          style={{ width: "100%", textAlign: "left" }}
                          onChange={(e) => {
                            setTipoPerfil(e);
                            console.log(
                              ref_form.current.resetFields(["organizacion"])
                            );
                          }}
                        >
                          <Option value="Membresía">Membresía</Option>
                          <Option value="Conferencistas">Conferencistas</Option>
                          <Option value="Invitados Especiales">
                            Invitados Especiales
                          </Option>
                          <Option value="Medios de Comunicación">
                            Medios de Comunicación
                          </Option>
                          <Option value="Equipo AMPIP">Equipo AMPIP</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    {tipo_perfl === "Membresía" && (
                      <Col span={24}>
                        <Form.Item
                          className="nom-label"
                          name="membresia"
                          label={
                            <span className="labels ">
                              Membresía{" "}
                              <small>
                                (Da clic en la flecha para seleccionar la opción
                                deseada)
                              </small>
                              :
                            </span>
                          }
                          rules={[
                            {
                              required: true,
                              message: (
                                <span style={{ color: "red" }} className="">
                                  {idioma === "es"
                                    ? "Selecciona una membresía"
                                    : "Select a membership"}{" "}
                                </span>
                              ),
                            },
                          ]}
                        >
                          <Select
                            style={{ width: "100%", textAlign: "left" }}
                            onChange={(e) => {
                              setTipoMembresia(e);
                              console.log(
                                ref_form.current.resetFields(["organizacion"])
                              );
                            }}
                          >
                            <Option value="Advance Real Estate">
                              Advance Real Estate
                            </Option>
                            <Option value="American Industries">
                              American Industries
                            </Option>
                            <Option value="American Industries Parque Industrial San Jorge ">
                              American Industries Parque Industrial San Jorge{" "}
                            </Option>
                            <Option value="Artha Capital">Artha Capital</Option>
                            <Option value="ATISA Industrial ">
                              ATISA Industrial{" "}
                            </Option>
                            <Option value="Bancomext">Bancomext</Option>
                            <Option value="BTS Development">
                              BTS Development
                            </Option>
                            <Option value="Cactus Valley Industrial Parks">
                              Cactus Valley Industrial Parks
                            </Option>
                            <Option value="CBRE">CBRE</Option>
                            <Option value="Central Business Park Mérida">
                              Central Business Park Mérida
                            </Option>
                            <Option value="CIESA">CIESA</Option>
                            <Option value="COFOIN, Gobierno del Estado de Hidalgo">
                              COFOIN, Gobierno del Estado de Hidalgo
                            </Option>
                            <Option value="Colliers">Colliers</Option>
                            <Option value="Copachisa">Copachisa</Option>
                            <Option value="Corporate Properties">
                              Corporate Properties
                            </Option>
                            <Option value="Corporativo MS">
                              Corporativo MS
                            </Option>
                            <Option value="Credit Suisse">Credit Suisse</Option>
                            <Option value="Crocsa">Crocsa</Option>
                            <Option value="Davisa Development Corporation">
                              Davisa Development Corporation
                            </Option>
                            <Option value="Deltack">Deltack</Option>
                            <Option value="El Florido Parque Industrial ">
                              El Florido Parque Industrial{" "}
                            </Option>
                            <Option value="Empire Roofing ">
                              Empire Roofing{" "}
                            </Option>
                            <Option value="EQT Exeter">EQT Exeter</Option>
                            <Option value="Estructuras Solana ">
                              Estructuras Solana{" "}
                            </Option>
                            <Option value="Evalta">Evalta</Option>
                            <Option value="FIBRA Macquarie ">
                              FIBRA Macquarie{" "}
                            </Option>
                            <Option value="FIBRA MTY">FIBRA MTY</Option>
                            <Option value="FIBRA UNO (FUNO)">
                              FIBRA UNO (FUNO)
                            </Option>
                            <Option value="FIDECIX, Gobierno del Estado de Tlaxcala">
                              FIDECIX, Gobierno del Estado de Tlaxcala
                            </Option>
                            <Option value="FIDEPAR, Gobierno del Estado de México">
                              FIDEPAR, Gobierno del Estado de México
                            </Option>
                            <Option value="FINSA">FINSA</Option>
                            <Option value="Flō Networks">Flō Networks</Option>
                            <Option value="Fortem Capital">
                              Fortem Capital
                            </Option>
                            <Option value="GP Desarrollos">
                              GP Desarrollos
                            </Option>
                            <Option value="Grupo Avante">Grupo Avante</Option>
                            <Option value="Grupo Ayusa">Grupo Ayusa</Option>
                            <Option value="Grupo Dragón">Grupo Dragón</Option>
                            <Option value="Grupo Marabis">Grupo Marabis</Option>
                            <Option value="Grupo Nelson ">Grupo Nelson </Option>
                            <Option value="Hermosillo ">Hermosillo </Option>
                            <Option value="Hikvision">Hikvision</Option>
                            <Option value="Hines ">Hines </Option>
                            <Option value="Hitachi Energy">
                              Hitachi Energy
                            </Option>
                            <Option value="IAMSA Development Group">
                              IAMSA Development Group
                            </Option>
                            <Option value="Iberdrola México">
                              Iberdrola México
                            </Option>
                            <Option value="Intermex Parques Industriales">
                              Intermex Parques Industriales
                            </Option>
                            <Option value="Interpuerto Monterrey">
                              Interpuerto Monterrey
                            </Option>
                            <Option value="ISOCINDU">ISOCINDU</Option>
                            <Option value="JLL">JLL</Option>
                            <Option value="Kampus Desarrollos">
                              Kampus Desarrollos
                            </Option>
                            <Option value="Kansas City">Kansas City</Option>
                            <Option value="Kingspan">Kingspan</Option>
                            <Option value="Lintel">Lintel</Option>
                            <Option value="Logistik">Logistik</Option>
                            <Option value="MEOR">MEOR</Option>
                            <Option value="MTZ Aerospace">MTZ Aerospace</Option>
                            <Option value="Nicoya Inversiones">
                              Nicoya Inversiones
                            </Option>
                            <Option value="O'Donnell">O'Donnell</Option>
                            <Option value="Oradel">Oradel</Option>
                            <Option value="Owens Corning Mexico">
                              Owens Corning Mexico
                            </Option>
                            <Option value="Parque Industrial AeroTech">
                              Parque Industrial AeroTech
                            </Option>
                            <Option value="Parque Industrial Atitalaquia">
                              Parque Industrial Atitalaquia
                            </Option>
                            <Option value="Parque Industrial Bermúdez">
                              Parque Industrial Bermúdez
                            </Option>
                            <Option value="Parque Industrial Calafia ">
                              Parque Industrial Calafia{" "}
                            </Option>
                            <Option value="Parque Industrial Maran">
                              Parque Industrial Maran
                            </Option>
                            <Option value="Parque Industrial Millennium ">
                              Parque Industrial Millennium{" "}
                            </Option>
                            <Option value="Parque Industrial Opción">
                              Parque Industrial Opción
                            </Option>
                            <Option value="Parque Industrial PyME ">
                              Parque Industrial PyME{" "}
                            </Option>
                            <Option value="Parque Industrial Yucatán">
                              Parque Industrial Yucatán
                            </Option>
                            <Option value="Parque Logístico San Julián">
                              Parque Logístico San Julián
                            </Option>
                            <Option value="Parques Industriales Amistad">
                              Parques Industriales Amistad
                            </Option>
                            <Option value="PGIM Real Estate ">
                              PGIM Real Estate{" "}
                            </Option>
                            <Option value="PIMSA Desarrolladores Industriales">
                              PIMSA Desarrolladores Industriales
                            </Option>
                            <Option value="Prologis">Prologis</Option>
                            <Option value="Prosperity Industrial ">
                              Prosperity Industrial{" "}
                            </Option>
                            <Option value="Puerta del Norte">
                              Puerta del Norte
                            </Option>
                            <Option value="ROCA Desarrollos">
                              ROCA Desarrollos
                            </Option>
                            <Option value="Savills">Savills</Option>
                            <Option value="SEDECO, Gobierno del Estado de Durango">
                              SEDECO, Gobierno del Estado de Durango
                            </Option>
                            <Option value="SEZAC, Gobierno del Estado de Zacatecas">
                              SEZAC, Gobierno del Estado de Zacatecas
                            </Option>
                            <Option value="SkyPlus Industrial and Logistik Park">
                              SkyPlus Industrial and Logistik Park
                            </Option>
                            <Option value="STIVA">STIVA</Option>
                            <Option value="Terra Regia">Terra Regia</Option>
                            <Option value="Terrafina">Terrafina</Option>
                            <Option value="Tetakawi">Tetakawi</Option>
                            <Option value="Top Management">
                              Top Management
                            </Option>
                            <Option value="VESTA">VESTA</Option>
                            <Option value="Vivaro ">Vivaro </Option>
                            <Option value="VYNMSA">VYNMSA</Option>
                            <Option value="Walton Street Capital Mexico">
                              Walton Street Capital Mexico
                            </Option>
                            <Option value="World Port">World Port</Option>
                            <Option value="WTC Industrial">
                              WTC Industrial
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    )}

                    {tipo_membresia === "Otro" && (
                      <Col span={24}>
                        <Form.Item
                          className="nom-label"
                          name="organizacion"
                          label={
                            <span className="labels">
                              {idioma === "es"
                                ? "Ingrese una membresía:"
                                : "Enter a membership:"}
                            </span>
                          }
                          rules={[
                            {
                              required: true,
                              message: (
                                <span style={{ color: "red" }}>
                                  {idioma === "es"
                                    ? "Ingrese una membresía:"
                                    : "Enter a membership:"}
                                </span>
                              ),
                            },
                          ]}
                          initialValue=""
                        >
                          <Input style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                    )}
                    <Col span={24}>
                      <Form.Item
                        className="nom-label"
                        name="cargo"
                        label={
                          <span className="labels">
                            {idioma === "es" ? "Cargo:" : "Position:"}
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: (
                              <span style={{ color: "red" }}>
                                {idioma === "es"
                                  ? "Ingresa tu cargo"
                                  : "Enter your position"}
                              </span>
                            ),
                          },
                        ]}
                      >
                        <input className="inputs" autoComplete="off" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <p style={{ textAlign: "left" }}><span style={{ fontSize: '18px', fontWeight:'bold' }} className="color_blanco">Comités en los que participará:</span></p>
                    </Col>
                    <Col span={24} style={{ textAlign: "left" }}>
                      <Form.Item
                        className="nom-label"
                        name="comite_promocion"
                        valuePropName="checked"
                        rules={[
                          {
                            required: false
                          }
                        ]}
                      >
                        <Checkbox className="nom-label">
                            <span style={{ fontSize: '16px' }} className="color_blanco">Comité de Promoción</span>
                          </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={24} style={{ textAlign: "left" }}>
                      <Form.Item
                        className="nom-label"
                        name="comite_asg"
                        valuePropName="checked"
                        rules={[
                          {
                            required: false
                          }
                        ]}
                      >
                        <Checkbox className="nom-label">
                            <span style={{ fontSize: '16px' }} className="color_blanco">Comité ASG</span>
                          </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={24} style={{ textAlign: "left" }}>
                      <Form.Item
                        className="nom-label"
                        name="comite_infraestructura"
                        valuePropName="checked"
                        rules={[
                          {
                            required: false
                          }
                        ]}
                      >
                        <Checkbox className="nom-label">
                            <span style={{ fontSize: '16px' }} className="color_blanco">Comité de Infraestructura y Seguridad</span>
                          </Checkbox>
                      </Form.Item>
                    </Col>

                    {/*<Col span={24}>
                        <Form.Item className="nom-label"
                          name="medio"
                          label={<span className="color_gris_oscuro">¿Por qué medio te enteraste del evento?</span>}
                          rules={[
                            {
                              required: true,
                              message: <span style={{ color: 'red' }}></span>,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      tipo_registro === 'presencial' &&
         <Col span={24} style={{textAlign:'left'}}>
         <Form.Item className="nom-label"
     name="asistir_miercoles"
     valuePropName="checked"
     rules={[
       {
         required:false
       }
     ]}
   >
     <Checkbox className="nom-label">
     <span style={{fontSize:'16px'}} className="">¿Asistirás a la cena de socios del miércoles 10 de noviembre del 2021?</span>
     </Checkbox>
   </Form.Item>
         </Col>
    */}

                    {/*<Col span={24} style={{ textAlign: 'left' }}>
                        <Form.Item className="nom-label"
                          name="aceptar_networking"
                          valuePropName="checked"
                          rules={[
                            {
                              required: false
                            }
                          ]}
                        >
                          <Checkbox className="nom-label">
                            <span style={{ fontSize: '16px' }} className="">Acepto que mis datos sean utilizados para fines estadísticos.</span>
                          </Checkbox>
                        </Form.Item>
                        </Col>*/}

                    <Col span={24} style={{ textAlign: "left" }}>
                      <Form.Item
                        className="nom-label"
                        name="aceptar_terminos"
                        valuePropName="checked"
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject(
                                    <span style={{ color: "red" }}>
                                      {idioma === "es"
                                        ? "He leído y acepto los términos y condiciones."
                                        : "I have read and accept the terms and conditions."}
                                    </span>
                                  ),
                          },
                        ]}
                      >
                        <Checkbox className="nom-label">
                          <span
                            className="color_blanco"
                            style={{ fontSize: "16px" }}
                          >
                            {idioma === "es" ? (
                              <>
                                Acepto el{" "}
                                <span
                                  onClick={() => {
                                    window.open(
                                      "http://www.ampip.org.mx/aviso.php",
                                      "Aviso de privacidad",
                                      "top=100,left=500,width=600,height=600"
                                    );
                                  }}
                                >
                                  <strong>Aviso de Privacidad</strong>
                                </span>
                              </>
                            ) : (
                              <>
                                I accept{" "}
                                <span
                                  onClick={() => {
                                    window.open(
                                      "http://www.ampip.org.mx/aviso.php",
                                      "Aviso de privacidad",
                                      "top=100,left=500,width=600,height=600"
                                    );
                                  }}
                                >
                                  <strong>the Privacy Notice</strong>
                                </span>
                              </>
                            )}
                          </span>
                        </Checkbox>
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item>
                        <br />
                        <p style={{ textArlign: "center" }}>
                          <span
                            className="btn_aqua"
                            onClick={() => {
                              ref_form.current.submit();
                            }}
                          >
                            {idioma === "es" ? "REGISTRO" : "REGISTER"}
                          </span>
                        </p>
                        <br />
                      </Form.Item>
                    </Col>
                  </Row>

                  <div id="recaptcha-container"></div>
                </Form>
              </Col>
              <Col xs={{ offset: 2, span: 20 }} md={{ offset: 6, span: 12 }}>
                <a
                  href="https://api.whatsapp.com/send?phone=525557400333&text=Tengo%20problemas%20con%20mi%20registro%20en%20AMPIP."
                  target="_blank"
                >
                  <img
                    style={{ width: "100%" }}
                    src={idioma === "es" ? img_ayuda : img_ayuda_en}
                    alt="Entrar"
                  />
                </a>
              </Col>
              <Col span={24}>
                <br />
                <br />
              </Col>
              <Col span={24} className="back_degradado paddin_contenedor">
                <Row align="middle">
                  <Col
                    xs={{ span: 12, offset: 2 }}
                    md={{ span: 14, offset: 2 }}
                  >
                    <p className="dudas_btn">
                      {idioma === "es"
                        ? "Tengo dudas sobre mi inscripción"
                        : "I have questions about my registration"}
                    </p>
                  </Col>
                  <Col xs={{ span: 0, offset: 0 }} md={{ span: 6, offset: 0 }}>
                    <a
                      href={
                        idioma === "es"
                          ? "https://api.whatsapp.com/send?phone=525557400333&text=Tengo%20problemas%20con%20mi%20registro%20en%20AMPIP."
                          : "https://api.whatsapp.com/send?phone=525557400333&text=I%20have%20problems%20with%20my%20AMPIP%20registration."
                      }
                      target="_blank"
                    >
                      <img
                        src={idioma === "es" ? btn_ayuda : btn_ayuda_en}
                        style={{ width: "180px" }}
                      />
                    </a>
                  </Col>
                  <Col xs={{ span: 8, offset: 0 }} md={{ span: 0, offset: 0 }}>
                    <a
                      href={
                        idioma === "es"
                          ? "https://api.whatsapp.com/send?phone=525557400333&text=Tengo%20problemas%20con%20mi%20registro%20en%20AMPIP."
                          : "https://api.whatsapp.com/send?phone=525557400333&text=I%20have%20problems%20with%20my%20AMPIP%20registration."
                      }
                      target="_blank"
                    >
                      <img
                        src={idioma === "es" ? btn_ayuda : btn_ayuda_en}
                        style={{ width: "100px" }}
                      />
                    </a>
                  </Col>
                </Row>
              </Col>
            </>
          )}
        </Row>
      </Spin>

      <Modal
        title="INICIA SESIÓN"
        visible={mostrar_login}
        onOk={() => {
          setMostrarLogin(false);
        }}
        onCancel={() => {
          setMostrarLogin(false);
        }}
        footer={null}
      >
        <LoginExiste
          onResponse={() => {
            registrarLoginExiste();
          }}
          onClick={success}
        />
      </Modal>
    </>
  );
};

export default withRouter(Registro);
