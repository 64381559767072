import React, { useState } from 'react';
import { Row, Col, Carousel, Button, Typography, Input } from 'antd';
import '../../assets/css/sala.css';

const { Text } = Typography;

let it_pregunta = 1;
let usuario_respuestas = {};


let preguntas_comparar = [
    [
        "Sí",
        "No",
    ],
    [
        "A pesar de cambios en regulaciones, energías limpias seguirán avanzando",
        "Cambios que está haciendo el gobierno ayudarán a un futuro mejor",
        "Existe mucha incertidumbre, no veo claro el panorama"
    ],
    [
        "Nuevos criterios de evaluación de los inversionistas",
        "Compromiso para sumarse a Objetivos de Desarrollo Sostenible",
        "Funciona como parte del marketing empresarial"
    ]
];

export const Preguntas = (props) => {

    const [respuesta_elegida, setRespuestaElegida] = useState(false);
    const { preguntas, onResponse } = props;
    const refCarouselPreguntas = React.createRef();

    const onChangeRespuesta = (value) => {

        let respuesta_valida = false;

        for(let i=0; i < preguntas_comparar[it_pregunta-1].length; i++){
            console.log(preguntas_comparar[it_pregunta-1][i]);
            console.log(value.target.value);
            if(preguntas_comparar[it_pregunta-1][i] === value.target.value){
                respuesta_valida = true;
            }
        }

        if(respuesta_valida){
            usuario_respuestas['pregunta' + it_pregunta] = value.target.value;
        }else{
            usuario_respuestas['pregunta' + it_pregunta] = preguntas_comparar[it_pregunta-1][0];
        }

        setRespuestaElegida(true);

    }

    const siguientePregunta = () => {
        setRespuestaElegida(false);
        ++it_pregunta;
        if (it_pregunta > preguntas.length) {
            console.log(usuario_respuestas);
            onResponse(usuario_respuestas);
        } else {
            refCarouselPreguntas.current.next();
        }
    }

    let divs_preguntas = preguntas.map(pregunta => {

        const respuestas = Object.values(pregunta.respuestas).map(respuesta => {
            return (
                <Row align="middle" style={{ textAlign: 'center', marginTop: '15px' }}>

                    <Col span={1} style={{ textAlign: 'right' }}>

                        <Input name="resp" type="radio" value={respuesta} onClick={onChangeRespuesta} className="back_azul" style={{border:'0px solid white !important'}} />
                    </Col>
                    <Col span={22} offset={0} style={{ textAlign: 'left', paddingLeft:'5px' }}>

                        <Text>{respuesta}</Text>
                    </Col>
                </Row>



            );
        });

        return (
            <div>
                <Row style={{border:'1px solid lightgray', background: 'white'}}>
                    <Col span={24} style={{ paddingTop:'10px', paddingBottom:'10px' }} className="back_azul">
                        <Text  strong style={{ color: 'white', fontSize: '16px', textAlign:'left' }}>{pregunta.pregunta}</Text>
                    </Col>
                    <Col span={22} offset={1}>

                        {respuestas}


                    </Col>
                    <Col span={24} style={{  textAlign:'right' }}>
                    <button style={{color: 'white', fontSize:'22px', borderRadius:'3px' }} onClick={siguientePregunta} disabled={!respuesta_elegida} className="back_azul" ><strong>VOTAR</strong></button>
                    <br />
                    </Col>
                </Row>


            </div>
        );


    });

    return (
        <Carousel dots={false} ref={refCarouselPreguntas} effect="fade">
            {divs_preguntas}
        </Carousel>
    );

}
